import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { isTimeValid, getValidFormatDatePerYear } from '../utils/CouponUtils';
import ImageComponent from '../../../atoms/Image';
import { DEFAULT_IMAGE_PATH, TERMS_AND_CONDITIONS, OFFER_APPLIED, REDEEM } from '../Constants/CouponConstants';
import { ButtonComp } from '../../../atoms/Buttons';
import TimerComponent from './TimerComponent';
import { sanitizeContent, getFullImagePath } from 'common/utilities/utils';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { TENANT_IMAGE_BASE_URL } from '@kfc-global/react-shared/config/config.constants';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';

/**
 * Component to render Coupon Card for mobile devices
 * @param {*} props
 * @returns JSX Element
 */
const CouponCardMobile = props => {
  const { contentData, tenantId, applyCoupon, tenantData } = props;
  /**
   * State variable to set the currently active accordian item
   */
  const [clickedItem, setClickeditem] = useState(null);
  /**
   * State variable to set the currently open terms and condtions
   */
  const [openTermsId, setOpenTermsId] = useState(null);
  const { TimerIcon, Time_ClockDark, ArrowIcon } = IMAGE_PATH;

  /**
   * Method to handle the button to open the accordian component
   * @param {Number} id - Clicked ID
   */
  const expandAccordian = id => {
    if (id === clickedItem) {
      setClickeditem(null);
    } else {
      setClickeditem(id);
    }
    setOpenTermsId(null);
  };

  /**
   *
   * @param {Object} e - event
   * @param {Number} id - Clicked ID
   */
  const expandTerms = (e, id) => {
    e.stopPropagation();
    if (id === openTermsId) {
      setOpenTermsId(null);
    } else {
      setOpenTermsId(id);
    }
  };

  const getTimerAndHeader = data => {
    return (
      <>
        {data?.showTimer ? (
          isTimeValid(data?.endTime) && (
            <p className='coupon-time'>
              <span data-testid='coupon-time-block' className='coupon-time-block'>
                <img className='coupon-time-icon' src={TimerIcon} alt='Coupon Time' />
                <TimerComponent endTime={data?.endTime} />
              </span>
            </p>
          )
        ) : (
          <p className='mr-btm-0'>
            {data?.header && <span className='ml-rgt coupon-time'>{data?.header}</span>}
            <span className='coupon-expiry-details'>
              <img alt={'time_clock'} src={Time_ClockDark} />
              <span>
                <span className='capitalize'>Expires&nbsp;</span> on {getValidFormatDatePerYear(data?.endTime)}
              </span>
            </span>
          </p>
        )}
      </>
    );
  };

  /**
   * Method to render cards without image
   * @param {Object} data - Data source to render coupon details
   * @param {Number} id - Unique ID
   * @returns JSX.Element
   */
  const getTextComponent = (data, id) => (
    <div
      data-testid='coupon-text-component'
      key={id}
      onClick={() => expandAccordian(id)}
      className='coupon-card-container'
    >
      <div className='coupon-card-content'>
        <div
          className={
            clickedItem === id ? 'coupon-text-content coupon-text-only show' : 'coupon-text-content coupon-text-only'
          }
        >
          <div className='coupon-text-limiter'>
            {getTimerAndHeader(data)}

            <h4 data-testid='coupon-heading-block' className='coupon-heading'>
              {sanitizeContent(data?.heading)}
            </h4>
          </div>
          <div
            className={clickedItem === id ? 'coupon-accordion-content show' : 'coupon-accordion-content'}
            data-testid='coupon-accordian-content'
          >
            <h5 className='coupon-desc' data-testid='coupon-desc-block'>
              {sanitizeContent(data?.description)}
            </h5>
            <ButtonComp
              className='button whiteButton coupon-button blackBorder'
              disabled={data?.isApplied}
              onClick={e => applyCoupon(e, data?.discountCode)}
              data-testid='apply-button'
            >
              {data?.isApplied ? translateWithI18Next(OFFER_APPLIED) : translateWithI18Next(REDEEM)}
            </ButtonComp>
            <h5 className='coupon-terms' data-testid='coupon-terms-block' onClick={e => expandTerms(e, id)}>
              {translateWithI18Next(TERMS_AND_CONDITIONS)}
              <img
                className={openTermsId === id ? 'terms-arrow inverted' : 'terms-arrow'}
                src={ArrowIcon}
                alt='Footer Down'
              />
            </h5>
          </div>
        </div>
        <div className='coupon-accordian-button' data-testid='accordian-toggle-button'>
          <img
            className={clickedItem === id ? 'coupon-accordian-arrow inverted' : 'coupon-accordian-arrow'}
            src={ArrowIcon}
            alt='Footer Down'
          />
        </div>
      </div>
      <div className={openTermsId === id ? 'terms-list show' : 'terms-list'} data-testid='terms-list-block'>
        <ul data-testid='mainterms-list' className='main-terms-list'>
          {data?.termsAndConditions?.map(terms => {
            return (
              <li id='terms-list' key={terms?.message}>
                {terms?.message}
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );

  /**
   * Method to render cards with image
   * @param {Object} data - Data source to render coupon details
   * @param {Number} id - Unique Id
   * @returns JSX.Element
   */
  const getImageTextComponent = (data, id) => (
    <div
      data-testid='coupon-text-component'
      key={id}
      onClick={() => expandAccordian(id)}
      className='coupon-card-container'
    >
      <div className='coupon-card-content'>
        <div className='coupon-mobile-content'>
          <div className='coupon-mobile-image-text'>
            <div className='coupon-image-content'>
              <ImageComponent
                className='coupon-image'
                srcFile={getFullImagePath(data?.imageSrc, tenantData, false, false, true)}
                alt={'No-Image'}
                defaultPath={`${config(TENANT_IMAGE_BASE_URL)}${tenantId}${DEFAULT_IMAGE_PATH}`}
              />
            </div>
            <div className='coupon-text-content with-image'>
              {getTimerAndHeader(data)}
              <h4 data-testid='coupon-heading-block' className='coupon-heading'>
                {sanitizeContent(data?.heading)}
              </h4>
            </div>
          </div>
          <div
            data-testid='coupon-accordian-content'
            className={
              clickedItem === id
                ? 'coupon-text-content coupon-below-image show'
                : 'coupon-text-content coupon-below-image'
            }
          >
            <div className={'coupon-accordion-content show'}>
              <h5 data-testid='coupon-desc-block' className='coupon-desc'>
                {sanitizeContent(data?.description)}
              </h5>
              <ButtonComp
                data-testid='apply-button'
                onClick={e => applyCoupon(e, data?.discountCode)}
                disabled={data?.isApplied}
                className='button whiteButton coupon-button blackBorder'
              >
                {data?.isApplied ? translateWithI18Next(OFFER_APPLIED) : translateWithI18Next(REDEEM)}
              </ButtonComp>
              <h5 data-testid='coupon-terms-block' onClick={e => expandTerms(e, id)} className='coupon-terms'>
                {translateWithI18Next(TERMS_AND_CONDITIONS)}
                <img
                  src={ArrowIcon}
                  className={openTermsId === id ? 'terms-arrow inverted' : 'terms-arrow'}
                  alt='Footer Down'
                />
              </h5>
            </div>
          </div>
        </div>
        <div data-testid='accordian-toggle-button' className='coupon-accordian-button'>
          <img
            src={ArrowIcon}
            className={clickedItem === id ? 'coupon-accordian-arrow inverted' : 'coupon-accordian-arrow'}
            alt='Footer Down'
          />
        </div>
      </div>
      <div data-testid='terms-list-block' className={openTermsId === id ? 'terms-list show' : 'terms-list'}>
        {/* <h4 className='terms-headline'>{TERMS_HEADLINE}</h4> */}
        <ul className='main-terms-list'>
          {data?.termsAndConditions?.map(terms => (
            <li key={terms?.message}>{terms?.message}</li>
          ))}
        </ul>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      {contentData.map((data, id) => {
        return data.cardType === 'image-text' ? getImageTextComponent(data, id) : getTextComponent(data, id);
      })}
    </React.Fragment>
  );
};

CouponCardMobile.propTypes = {
  contentData: PropTypes.array,
  tenantId: PropTypes.string,
  applyCoupon: PropTypes.func,
  tenantData: PropTypes.object,
};

CouponCardMobile.defaultProps = {
  contentData: [],
  tenantId: '',
  applyCoupon: null,
  tenantData: {},
};

export default CouponCardMobile;
