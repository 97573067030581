import React from 'react';
import PropTypes from 'prop-types';
import { modifyData } from '../utils/CouponUtils';
import CouponCardDesktop from '../Presentation/CouponCardDesktop';
import CouponCardMobile from '../Presentation/CouponCardMobile';

/**
 * Component to render coupons list inside modal based on screen-width
 * @param {*} props
 * @props isMobile: To determine if the screen-width falls under Mobile category
 * @returns JSX.Element
 */
const CouponsList = props => {
  const { isMobile, couponsData, tenantId, applyCoupon, tenantData } = props;

  /**
   * Modifying API response into mappable data
   */
  const contentData = modifyData(couponsData);

  /**
   * Method to return CouponCard component for desktop
   * @returns JSX.Element
   */
  const renderCouponDesktop = () =>
    contentData.map((data, id) => {
      return (
        <CouponCardDesktop
          data={data}
          tenantId={tenantId}
          cardType={data?.cardType}
          id={id}
          key={id}
          applyCoupon={applyCoupon}
          tenantData={tenantData}
        />
      );
    });

  return (
    <React.Fragment>
      {isMobile ? (
        <CouponCardMobile
          contentData={contentData}
          applyCoupon={applyCoupon}
          tenantId={tenantId}
          tenantData={tenantData}
        />
      ) : (
        renderCouponDesktop()
      )}
    </React.Fragment>
  );
};

CouponsList.propTypes = {
  isMobile: PropTypes.bool,
  couponsData: PropTypes.array,
  tenantId: PropTypes.string,
  applyCoupon: PropTypes.func,
  tenantData: PropTypes.object,
};

CouponsList.defaultProps = {
  isMobile: false,
  couponsData: [],
  tenantId: '',
  applyCoupon: null,
  tenantData: {},
};

export default CouponsList;
