import React, { useState, useEffect } from 'react';
import { isTimeValid, getTimeString } from '../utils/CouponUtils';

const TimerComponent = props => {
  const { endTime = new Date() } = props;
  const [timeLeft, setTimeLeft] = useState(getTimeString(endTime));

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    let timer;
    if (isTimeValid(endTime)) {
      timer = setTimeout(() => {
        setTimeLeft(getTimeString(endTime));
      }, 1000);
    } else {
      setTimeLeft('00HR : 00M : 00S');
    }
    return () => {
      clearTimeout(timer);
    };
  });

  return (
    <span data-testid='timer' aria-live='polite'>
      {timeLeft}
    </span>
  );
};

export default TimerComponent;
