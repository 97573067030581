import { LOCAL_STORAGE_KEYS, DATE_FORMATS } from 'common/constants/SharedConstants';
import { getCurrentDateTimeWithTimeZoneInUnix } from 'common/utilities/dayJsUtils';
const checkScheduledDateTime = timeZone => {
  const scheduleTime = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS?.DATE_TIME_ORDER));
  const currentEpocTime = getCurrentDateTimeWithTimeZoneInUnix(timeZone);
  return scheduleTime?.epochTime > currentEpocTime;
};
export const convertTimeTo24Zone = (currentT, timeZone) => {
  const scheduleTime = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS?.DATE_TIME_ORDER));
  if (scheduleTime) {
    const [time, modifier] = scheduleTime?.selectedTime?.split(' ');
    let [hours, minutes] = time.split(':');
    if (hours === DATE_FORMATS?.TWELVE_TIME) {
      hours = DATE_FORMATS?.ZERO_HOURS;
    }
    if (hours?.length === 1) {
      hours = '0' + hours;
    }
    if (minutes?.length === 1) {
      minutes = '0' + minutes;
    }
    if (modifier === DATE_FORMATS?.PM) {
      hours = parseInt(hours, 10) + 12;
    }
    const scheduleTimeInFormat = `${hours}${minutes}`;
    if (+scheduleTimeInFormat > +currentT || checkScheduledDateTime(timeZone)) {
      currentT = scheduleTimeInFormat;
    }
  }
  return currentT;
};
