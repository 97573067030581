import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { getToastNotification } from '../sharedTemplates/toasterTemplates';

export const localizationToast = (storeName, dispositionType, showToastAfterLocalization) => {
  const { YOUR_NOW_LOCALIZED, AND_YOUR_ORDERING_FOR } = TRANSLATE_MAPPING_KEY;
  if (showToastAfterLocalization) {
    getToastNotification(
      `${translateWithI18Next(YOUR_NOW_LOCALIZED)} ${storeName} ${translateWithI18Next(
        AND_YOUR_ORDERING_FOR,
      )} ${dispositionType}`,
      4500,
      'TOP_LEFT',
      'localize-toast',
    );
  }
};
