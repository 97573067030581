export const OFFERS_AND_PROMOTIONS_CONSTANTS = {
  URL: '/images/offers/lg/',
  PNG: '.jpg',
  DEFAULT_IMAGE_PATH: '/images/items/no-image.jpg',
  OFFER_APPLIED: 'offerApplied',
  APPLIED: 'Applied',
  BASIC_RATE: 'BasicRate',
  HEADER: 'Header',
  SHOW_TIMER: 'ShowTimer',
  CONTENT_DATA_MAX_LENGTH_TO_SCROLL: 4,
  CONTENT_DATA_MAX_LENGTH_TO_SHOW: 9,
  TIME_IN_MILLISECONDS: 1000,
  VALIDITY_TEXT: 'Valid to',
  CAROUSAL_CONFIG: {
    SPEED: 200,
    SLIDES_TO_SHOW: 3,
    SLIDES_TO_SCROLL: 1,
  },
  BUTTON_TEXT: {
    VIEW_DETAILS: 'viewdetails',
    REDEEM: 'redeem',
  },
  OFFERS_HEADER_TEXT: {
    OFFERS_AND_DEALS: 'offerDeals',
    VIEW_ALL_DEALS: 'viewAllDeal',
  },
  FADE_CARD_INDEX: 3,
  COUPON_SUCCESS_TOAST_MESSAGE: 'fingerLickin',
};
