import React, { useEffect, useState } from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { PageTitle, KFC } from 'common/constants/SharedConstants';
import startCase from 'lodash/startCase';

export const ROUTE_PAGE_TITLE_KEY_MAP = {
  '/cart': PageTitle.CART,
  '/offers': PageTitle.OFFERS,
  '/login': PageTitle.LOGIN,
  '/help': PageTitle.HELP,
  '/checkout': PageTitle.CHECKOUT,
  '/payment-failure': PageTitle.PAYMENT_FAILURE,
  '/payment-success': PageTitle.PAYMENT_SUCCESS,
  '/payment-status-check': PageTitle.PAYMENT_STATUS,
  '/dgft-payment-status-check': PageTitle.PAYMENT_STATUS,
  '/ourads': PageTitle.OUR_ADDS,
  '/contactus': PageTitle.CONTACT_US,
  '/paymentCallback': PageTitle.PAYMENT_CALLBACK,
  '/contact-kfc': PageTitle.CONTACT_US,
  '/terms-of-use': PageTitle.TERMS_OF_USE,
  '/terms-and-conditions': PageTitle.TERMS_CONDITIONS,
  '/promo-terms': PageTitle.PROMO_TERMS,
  '/faq': PageTitle.FAQ,
  '/facts': PageTitle.FACTS,
  '/giftcards': PageTitle.GIFT_CARD,
  '/privacypolicy': PageTitle.PRIVACY_POLICY,
  '/signout': PageTitle.SIGN_OUT,
  '/account': PageTitle.ACCOUNT,
  '/order-processing': PageTitle.ORDER_PROCESSING,
  '/order-summary': PageTitle.ORDER_SUMMARY,
  '/about-kfc/caution-notice': PageTitle.CAUTION_NOTICE,
  '/find-store': PageTitle.FINDAKFC,
  '/addhope/thankyou': PageTitle.ADD_HOPE_THANK_YOU_Title,
  '/addhope/donate': PageTitle.ADD_HOPE,
  '/payment': PageTitle.PAYMENT,
  '/link-processing': PageTitle.LinkProcessing,
  '/mileage-loyalty': PageTitle.MileageLoyalty,
  '/rewards': PageTitle.REWARDS,
  '/otpscreen': PageTitle.OTP_SCREEN,
  '/rakuten-tc': PageTitle.RAKUTEN_TC,
  '/promos-rewards': PageTitle.PROMOS_REWARDS,
};

export const updatePagetitle = (titleKey, routePath) => {
  const localizedTitle = titleKey && translateWithI18Next(titleKey);
  return localizedTitle ?? startCase(routePath) ?? KFC;
};

const KFCRouter = ({ ...props }) => {
  const [pageTitle, setPageTitle] = useState('');
  const routePath = window?.location?.pathname?.trim().toLowerCase();

  useEffect(() => {
    setPageTitle(ROUTE_PAGE_TITLE_KEY_MAP?.[routePath]);
  }, [routePath]);

  return (
    <>
      <Helmet>
        <title>{updatePagetitle(pageTitle, routePath)}</title>
      </Helmet>
      <BrowserRouter {...props} />
    </>
  );
};

export default KFCRouter;
