import { ButtonComp } from 'atoms/Buttons';
import React, { useEffect, useState } from 'react';
import CouponsModal from '../../../CouponsLanding/Presentation/CouponsModal';
import { CART_PAGE_CONSTANTS } from '../../Constants/CartPageConstants';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';

const OffersCoupon = props => {
  const { bucketId = '' } = props;
  /**
   * state variable to open and close the coupon landing page (Modal)
   */
  const [openModal, toggleModal] = useState(false);
  const { OFFERS_COUPONS } = CART_PAGE_CONSTANTS;
  const { APPLY_OFFERS, VIEW_ALL } = OFFERS_COUPONS;
  const { OffersCoupnIcon } = IMAGE_PATH;
  const handleCloseModal = event => {
    toggleModal(false);
  };

  useEffect(() => {
    const closeOffersModal = eventofOffereModal => {
      if (eventofOffereModal.keyCode === 27) {
        handleCloseModal(eventofOffereModal);
      }
    };
    window.addEventListener('keydown', closeOffersModal);
    return () => window.removeEventListener('keydown', closeOffersModal);
  }, []);
  return (
    <div className='cart-offers-container' data-testid='cart-offers-coupon'>
      <div className='cart-offers-link'>
        <img src={OffersCoupnIcon} alt='' className='cart-offers-icon' />
        <span className='cart-offers-text'>{translateWithI18Next(APPLY_OFFERS)}</span>
      </div>
      <ButtonComp
        onClickHandler={() => toggleModal(true)}
        ariaLable='View all offers'
        className='button whiteButton blackBorder cart-offers-view-all'
        data-testid='offers-toggle-btn'
      >
        {translateWithI18Next(VIEW_ALL)}
      </ButtonComp>
      {openModal && (
        <CouponsModal
          data-testid='coupon-modal'
          bucketId={bucketId}
          isOpen={openModal}
          handleClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default OffersCoupon;
