import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import isEmpty from 'lodash/isEmpty';
import { getUrlImagePath } from 'common/utilities/utils';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { getGuestOtpToken, resetGuestOtp } from '@kfc-global/react-shared/redux/Actions/GuestOtpAction';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import Modal from '../../../molecules/Modal';
import { ButtonComp } from '../../../atoms/Buttons';
import {
  INPUT,
  MARKETING_OPT_EMAIL,
  MARKETING_OPT_IN,
  SEND_VERIFIATION_CODE,
  MARKETING_OPT_PHONE,
  MASKINPUT,
  TELEPHONE,
  PHONE_NUMBER,
  TERMS_AND_CONDITIONS,
  VERIFICATION_ICON,
  PHONE_NUMBER_NOTE,
  CHANGE_PHONE_NUMBER,
  IMAGE_SIZE_CONSTANTS,
  FORM_FIELD_TYPE,
  GUEST_OTP_VERIFICATION_SUCCESS,
  VERIFY_PHONE_NUMBER,
  CHANGE_NUMBER_BUTTON,
  EMAIL,
  LAST_NAME,
  GUEST_VALID_ERROR_MSG,
} from 'common/constants/SharedConstants';
import { RESTRICTED_BAD_WORDS } from 'common/constants/restrictionWords';
import { checkRestrictedWords } from 'organisms/AccountPage/AU/accountPageUtils';
import { resetGuestOtpReducer } from 'common/utilities/CheckoutUtils';
import { isDeliveryDisposition } from '../../../common/utilities/utils';
const { GUEST_OTP } = IMAGE_SIZE_CONSTANTS || {};
const { PictogramAlertIcon } = IMAGE_PATH || {};

export const validationMsg = (fields, showErrorMessageForGuestCheckout) => {
  const errorMessage = showErrorMessageForGuestCheckout
    ? `${translateWithI18Next(GUEST_VALID_ERROR_MSG[fields?.name.toUpperCase()])}`
    : `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_TEXT)} ${fields?.label?.toLowerCase()}`;
  return fields?.specialCharErrorMsg ? fields?.specialCharErrorMsg : errorMessage;
};

export const onClickVerifyPhoneNumberHandler = (componentData, fieldConfig) => {
  fieldConfig.dispatch(getGuestOtpToken());
  componentData?.checkoutDetails?.guestOtpTokenResponse &&
    !isEmpty(componentData?.checkoutDetails?.guestOtpId) &&
    componentData?.checkoutDetails?.userPhone?.value &&
    componentData?.updateCheckoutDetails({ openOtpModal: true });
};

export const onClickChangeButtonHandler = (componentData, fieldConfig) => {
  componentData?.updateCheckoutDetails({
    openGuestPhoneNumberChangeModal: true,
  });
};

export const updateFormElementConfigData = (
  fields,
  id,
  cookiesSelected,
  phoneMarketingOpt,
  guestCheckoutEmptyFieldErrorMessage,
  showErrorMessageForGuestCheckout,
) => {
  return {
    ...fields,
    key: id,
    id: id,
    phoneMarketingOpt: phoneMarketingOpt,
    rules: {
      required: {
        value: fields?.required ?? false,
        message: guestCheckoutEmptyFieldErrorMessage
          ? `${fields?.label?.toLowerCase()}${translateWithI18Next(guestCheckoutEmptyFieldErrorMessage)}`
          : `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.ENTER_YOUR_Name)} ${fields?.label?.toLowerCase()}`,
      },
      pattern: {
        value: fields?.pattern ? RegExp(fields?.pattern) : null,
        message: validationMsg(fields, showErrorMessageForGuestCheckout),
      },
      validate: textvalue =>
        fields?.dataType !== TELEPHONE && fields?.type === INPUT
          ? !checkRestrictedWords(RESTRICTED_BAD_WORDS, textvalue) ||
            `${translateWithI18Next(TRANSLATE_MAPPING_KEY?.INVALID_TEXT)} ${fields?.label?.toLowerCase()}`
          : true,
    },
    type: fields?.dataType === TELEPHONE ? MASKINPUT : fields?.type,
    phoneNumberMask: fields?.dataType === TELEPHONE ? fields?.phoneNumberMask : null,
    onChangeHandler: onFormElementChangeHandler,
    onBlurHandler: onFormElementChangeHandler,
    conditionalRender: false,
    htmlString: fields?.description ?? null,
    additionalDescription: fields?.optionalDescription ?? null,
    isMandatory: fields?.required ?? false,
    maxLength: fields?.maxLength ?? '',
    displayErrorMessage: fields?.name !== 'tnc',
    className: fields?.name === MARKETING_OPT_IN && !cookiesSelected ? 'hide-cookies' : '',
    errorClassName: fields?.name === TERMS_AND_CONDITIONS ? 'mandatory-checkbox-error' : '',
  };
};

export const getDisabledSendVerificationButton = formConfig => {
  if (!isEmpty(formConfig?.getValueFromElements?.()?.['phoneNumber'])) {
    if (!isEmpty(formConfig?.errors?.phoneNumber?.message)) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
};

export const getClassNameForChangeNumberButton = formConfig =>
  formConfig?.checkoutDetails?.guestOtpValidationMessge !== GUEST_OTP_VERIFICATION_SUCCESS
    ? 'changeNumber-disable'
    : 'changeNumber';

export const submitHandler = (e, updateCheckoutDetails, checkoutDetails) => {
  checkoutDetails?.dispatch?.(resetGuestOtp());
  !checkoutDetails?.isChangeButtonClicked &&
    updateCheckoutDetails({
      openGuestPhoneNumberChangeModal: false,
      isChangeButtonClicked: true,
      guestOtpCheckoutValidated: false,
    });
};

export const closeButtonHandler = (e, updateCheckoutDetails) => {
  updateCheckoutDetails({ openGuestPhoneNumberChangeModal: false });
};
export const ChangeGuestOtpNumber = (checkoutDetails, updateCheckoutDetails, handleClose) => {
  const modalProps = {
    role: 'dialog',
    modalTitleId: 'CheckBalancepopup',
    show: true,
    classNames: 'giftcard-getCardDetails-modal',
    showCloseBtn: true,
    closeButtonHandler: handleClose,
    autoFocus: true,
    modalTitle: 'test',
    closeDataTestId: 'giftcheck-balance',
  };
  return (
    <div className='tes' data-testid='giftcard-checkBal'>
      {
        <Modal {...modalProps}>
          <img src={PictogramAlertIcon} alt='error' className='mr-2 m-auto' />
          <div className='getDetailsModalHeader changeVerifyText'>
            {translateWithI18Next(TRANSLATE_MAPPING_KEY?.CHANGE_VERIFIED_NUMBER)}
          </div>
          <div className='getDetailsModalContent'>
            {translateWithI18Next(TRANSLATE_MAPPING_KEY?.GUEST_NUMBER_CHANGE_MSG)}
          </div>
          <div className='buttonGiftModal'>
            <ButtonComp
              data-testid='subm-button'
              className='button blackButton blackBorder save-button'
              onClick={e => submitHandler(e, updateCheckoutDetails, checkoutDetails)}
            >
              {translateWithI18Next(TRANSLATE_MAPPING_KEY?.CHANGE_NUMBER)}
            </ButtonComp>
          </div>
          <div className='buttonGiftModal'>
            <ButtonComp
              data-testid='subm-button'
              className='button whitebutton blackBorder cancel-btn'
              onClick={e => closeButtonHandler(e, updateCheckoutDetails)}
            >
              {translateWithI18Next(TRANSLATE_MAPPING_KEY?.CANCEL)}
            </ButtonComp>
          </div>
        </Modal>
      }
    </div>
  );
};

export const getUpdatedValue = (formConfig, fieldConfig) => {
  if (
    formConfig?.checkoutDetails?.isChangeButtonClicked ||
    formConfig?.checkoutDetails?.useDifferentNumberFlow ||
    formConfig?.checkoutDetails?.guestOtpValidationFailedAttemptsCount ===
      formConfig?.checkoutDetails?.maximumFailedOtpCount
  ) {
    formConfig?.setValueToFormElements(PHONE_NUMBER, '');
    formConfig.updateCheckoutDetails({
      isChangeButtonClicked: false,
      useDifferentNumberFlow: false,
    });
    resetGuestOtpReducer(formConfig?.checkoutDetails, formConfig?.updateCheckoutDetails);
    return '';
  } else {
    return fieldConfig?.value;
  }
};

export const optionalFieldClassName = (formConfig, optionalField) => {
  return optionalField?.value === '' && formConfig?.checkoutDetails?.isClickAddPayment ? 'disableLabel' : '';
};

export const prepareFormElmentConfig = (
  contentReducer,
  cookiesSelected,
  phoneMarketingOpt,
  checkoutDetails,
  hideNonfilledOptionalfields,
) => {
  const dataUpdated = [];
  contentReducer?.guestContentData?.items?.[0]?.fields?.formField?.forEach((formElement, index) => {
    const {
      fields,
      sys: { id },
    } = formElement;

    const guestCheckoutEmptyFieldErrorMessage = checkoutDetails?.guestCheckoutEmptyFieldErrorMessage;
    const showErrorMessageForGuestCheckout = checkoutDetails?.showErrorMessageForGuestCheckout;

    const configData = updateFormElementConfigData(
      fields,
      id,
      cookiesSelected,
      phoneMarketingOpt,
      guestCheckoutEmptyFieldErrorMessage,
      showErrorMessageForGuestCheckout,
    );

    if (checkoutDetails?.enableGuestOtpCheckout) {
      switch (formElement?.fields?.name || formElement?.fields?.title) {
        case VERIFY_PHONE_NUMBER:
          configData.group = 2;
          configData.displayOrder = 1;
          configData.className = `verifyPhoneNumber`;
          configData.name = VERIFY_PHONE_NUMBER;

          break;
        case PHONE_NUMBER_NOTE:
          configData.group = 2;
          configData.displayOrder = 2;
          configData.name = PHONE_NUMBER_NOTE;
          configData.type = FORM_FIELD_TYPE?.NOTE;
          configData.className = (formConfig, fieldsConfig) =>
            formConfig?.checkoutDetails?.guestOtpValidationMessge === GUEST_OTP_VERIFICATION_SUCCESS
              ? 'phoneNumberNote-disable'
              : 'phoneNumberNote';

          break;

        case PHONE_NUMBER:
          configData.group = 2;
          configData.displayOrder = 3;
          configData.readonly = formConfig =>
            formConfig?.checkoutDetails?.guestOtpValidationMessge === GUEST_OTP_VERIFICATION_SUCCESS;
          configData.updatedValue = (formConfig, fieldsConfig) => getUpdatedValue(formConfig, fieldsConfig);

          break;
        case CHANGE_PHONE_NUMBER:
          configData.group = 2;
          configData.displayOrder = 4;
          configData.name = CHANGE_NUMBER_BUTTON;
          configData.containerClassName = formConfig =>
            formConfig?.checkoutDetails?.guestOtpValidationMessge !== GUEST_OTP_VERIFICATION_SUCCESS
              ? 'buttonchange-disable'
              : 'buttonchange';
          configData.className = formConfig => getClassNameForChangeNumberButton(formConfig);
          configData.onClickHandler = onClickChangeButtonHandler;
          configData.conditionalRender = formConfig => !formConfig?.checkoutDetails?.isClickAddPayment;

          break;

        case SEND_VERIFIATION_CODE:
          configData.name = `sendVerificationCode`;
          configData.onClickHandler = onClickVerifyPhoneNumberHandler;
          configData.dispatch = checkoutDetails?.dispatch;
          configData.className = 'sendVerificationCode';
          configData.group = 2;
          configData.displayOrder = 5;
          configData.conditionalRender = formConfig =>
            formConfig?.checkoutDetails?.guestOtpValidationMessge !== GUEST_OTP_VERIFICATION_SUCCESS;
          configData.disabled = formConfig => getDisabledSendVerificationButton(formConfig);
          configData.onKeyDownHandler = (formConfig, fieldsConfig) => getDisabledSendVerificationButton(formConfig);

          break;
        case VERIFICATION_ICON:
          configData.group = 2;
          configData.displayOrder = 6;
          configData.containerClassName = (formConfig, fieldsConfig) =>
            formConfig?.checkoutDetails?.guestOtpValidationMessge === GUEST_OTP_VERIFICATION_SUCCESS
              ? 'verifyPhoneNumberIconContainer'
              : 'verifyPhoneNumberIconContainer-disable';
          configData.className = 'verifyPhoneNumberIcon';
          configData.type = FORM_FIELD_TYPE?.ICON;
          configData.image = getUrlImagePath(
            formElement?.fields?.desktopImage.fields.file.url,
            GUEST_OTP?.VERIFICATION_ICON_WIDTH,
            GUEST_OTP?.VERIFICATION_ICON_HEIGHT,
          );

          break;

        case MARKETING_OPT_IN:
        case 'tnc':
        case 'note':
          configData.group = 3;
          configData.displayOrder = index;
          break;
        default:
          configData.group = 1;
          configData.displayOrder = index;
      }
    }

    if (hideNonfilledOptionalfields) {
      if (formElement?.fields?.name === LAST_NAME) {
        configData.containerClassName = formConfig =>
          optionalFieldClassName(formConfig, formConfig?.checkoutDetails?.userLastName);
      }
      if (formElement?.fields?.name === EMAIL) {
        configData.containerClassName = formConfig =>
          optionalFieldClassName(formConfig, formConfig?.checkoutDetails?.userEmail);
      }
    }
    dataUpdated.push(configData);
    if (formElement?.fields?.name === MARKETING_OPT_IN) {
      formElement?.fields.values?.forEach(optParams =>
        dataUpdated.push({
          ...configData,
          additionalDescription: '',
          name: `marketingOpt${optParams}`,
          label: translateWithI18Next(optParams),
          className: 'ml-4',
          htmlString: '',
          type: 'checkbox',
          onChangeHandler: onFormElementChangeHandler,
          conditionalRender: true,
          rules: {
            required: {
              value: false,
              message: `${translateWithI18Next(
                TRANSLATE_MAPPING_KEY?.ENTER_YOUR_Name,
              )} ${formElement?.fields?.label?.toLowerCase()}`,
            },
            pattern: {
              value: formElement?.fields?.pattern ? RegExp(formElement?.fields?.pattern) : null,
              message: `${translateWithI18Next(
                TRANSLATE_MAPPING_KEY?.INVALID_TEXT,
              )} ${formElement?.fields?.label?.toLowerCase()}`,
            },
          },
        }),
      );
    }
  });
  return dataUpdated;
};
export const addressline2Check = checkoutDetails => {
  const { service, enableAddress2DetailsPanel, address2 } = checkoutDetails ?? {};

  return isDeliveryDisposition(service) && enableAddress2DetailsPanel ? address2 !== '' : true;
};

export const onFormElementChangeHandler = (formConfig, fieldConfig, phoneMarketingOpt) => {
  const fieldValues = formConfig?.getValueFromElements?.();
  const fieldData = {};
  const tempCheckoutDetails = {};

  if (
    fieldConfig?.name === 'firstName' ||
    fieldConfig?.name === 'lastName' ||
    fieldConfig?.name === 'Full Name' ||
    fieldConfig?.name === 'email' ||
    fieldConfig?.name === 'phoneNumber'
  ) {
    tempCheckoutDetails.cartDataCustomer = {
      ...formConfig?.checkoutDetails?.cartDataCustomer,
      [fieldConfig?.name]: fieldValues?.[fieldConfig?.name],
    };

    fieldData.value = fieldValues?.[fieldConfig?.name];
    fieldData.errorMessage = fieldConfig?.error?.message;
    fieldData.error = fieldConfig?.invalid;
  }

  switch (fieldConfig?.name) {
    case 'firstName':
      tempCheckoutDetails.userFirstName = fieldData;
      break;
    case 'lastName':
      tempCheckoutDetails.userLastName = fieldData;
      break;
    case 'Full Name':
      tempCheckoutDetails.userFullName = fieldData;
      break;
    case 'email':
      tempCheckoutDetails.userEmail = fieldData;
      break;
    case 'phoneNumber':
      tempCheckoutDetails.userPhone = fieldData;
      break;
    case 'tnc':
      tempCheckoutDetails.termsPolicyCheckbox = fieldValues?.[fieldConfig?.name];
      break;
    case MARKETING_OPT_IN:
    case MARKETING_OPT_EMAIL:
    case MARKETING_OPT_PHONE:
      updateMarketingOptInValue(formConfig, fieldConfig, fieldValues, tempCheckoutDetails);
      break;
    // no default
  }
  formConfig?.updateCheckoutDetails({
    ...tempCheckoutDetails,
    isContactDetailValid: formConfig?.checkoutDetails?.enableGuestOtpCheckout
      ? formConfig?.isFormValid && formConfig?.checkoutDetails?.guestOtpCheckoutValidated
      : formConfig?.isFormValid && addressline2Check(formConfig?.checkoutDetails),
    guestCheckOutFieldsValidated: formConfig?.isFormValid,
  });
};
export const updateMarketingOptInValue = (formConfig, fieldConfig, fieldValues, tempCheckoutDetails) => {
  let showAdditionalDescription = false;
  if (fieldConfig?.name === MARKETING_OPT_IN) {
    showAdditionalDescription = fieldValues?.marketingOptIn;
    formConfig?.setValueToFormElements(MARKETING_OPT_EMAIL, showAdditionalDescription);
    formConfig?.setValueToFormElements(
      MARKETING_OPT_PHONE,
      showAdditionalDescription && fieldConfig?.phoneMarketingOpt,
    );
  } else if (fieldConfig?.name === MARKETING_OPT_EMAIL || fieldConfig?.name === MARKETING_OPT_PHONE) {
    showAdditionalDescription = fieldValues?.marketingOptEmail || fieldValues?.marketingOptPhone;
    formConfig?.setValueToFormElements(MARKETING_OPT_IN, showAdditionalDescription);
  }

  const tempgFieldConfig = formConfig?.checkoutDetails?.guestCheckoutFormConfig?.fieldsConfig?.map(data => ({
    ...data,
    conditionalRender:
      data.name === MARKETING_OPT_EMAIL || data.name === MARKETING_OPT_PHONE
        ? !showAdditionalDescription
        : data?.conditionalRender,
    showAdditionalDescription,
  }));

  tempCheckoutDetails.guestCheckoutFormConfig = {
    ...formConfig?.checkoutDetails?.guestCheckoutFormConfig,
    fieldsConfig: tempgFieldConfig,
  };
  tempCheckoutDetails.marketingOptCheckbox = fieldValues?.marketingOptIn;
  tempCheckoutDetails.marketingOptInVal = {
    Email: fieldValues?.marketingOptEmail,
    Phone: fieldValues?.marketingOptPhone,
  };
  return tempCheckoutDetails;
};

export const resetGuestFormValue = updateCheckoutDetails => {
  updateCheckoutDetails({
    userEmail: { value: '', errorMessage: '', error: false },
    userFirstName: { value: '', errorMessage: '', error: false },
    userLastName: { value: '', errorMessage: '', error: false },
    userPhone: { value: '', errorMessage: '', error: false },
    termsPolicyCheckbox: false,
    isContactDetailValid: false,
    guestCheckOutFieldsValidated: false,
    VerfiyGuestOtpButtonEnabled: false,
  });
};
