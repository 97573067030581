import parse from 'html-react-parser';
import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import { RESTRICTED_BAD_WORDS } from 'common/constants/restrictionWords';
import events from '@kfc-global/react-shared/analytics/Register';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import get from 'lodash/get';
import Accordian from 'atoms/Accordian/Accordian';
import { currencyConversion } from 'organisms/PaymentProcess/Presentation/orderReceivedScreenFn';
import PAYMENT_PROCESS from 'organisms/PaymentProcess/Constants';
import { parseInvalidDateAndFormat } from 'common/utilities/dayJsUtils';
import { capitalizeDispositionType, getDataLayer, getTernaryValue } from 'common/utilities/utils';
import {
  ROUTE_URL,
  EVERYDAY,
  DAYS,
  DISPOSITION_KEYS,
  ANALYTICS_SHARED_CONSTANTS,
  REORDER_CONSTANTS,
  FULL_NAME_RESTRICTION,
  NUMBER_ONLY_REGEX,
} from 'common/constants/SharedConstants';
import { TRANSLATE_MAPPING_KEY } from 'common/constants/i18nTranslateKeys';
import { ACCOUNT_SETTINGS } from '../AU/Constants/Constants';
import dayjs from 'dayjs';
import isEmpty from 'lodash/isEmpty';
import localeData from 'dayjs/plugin/localeData';
dayjs.extend(localeData);

const getDate = (yearVal, currentYearVal, monthIndexVal) => new Date(yearVal ?? currentYearVal, monthIndexVal, 1);

const getMonthDateLastDay = ({ day, lastDay, monthIndex, year }) =>
  // eslint-disable-next-line eqeqeq
  day <= lastDay.getDate() || (monthIndex === 1 && ((day >= lastDay.getDate() + 1 && year) || day == 29));

const getDayFromLeapYearMonth = (monthIndexVal, yearVal) => monthIndexVal === 1 && dayjs(yearVal).isLeapYear();

const getDaySelected = dayVal => dayVal || false;

const getMonthSelected = monthVal => monthVal || false;

const getYearSelected = yearVal => yearVal || false;

const getdobErrorMsg = (isValidDateVal, yearVal) => {
  return !isValidDateVal && yearVal
    ? ACCOUNT_SETTINGS.DATE_MONTH_YEAR_ERROR_TEXT
    : ACCOUNT_SETTINGS.DAY_MONTH_YEAR_ERROR_MESSAGE;
};

const { HH_MM, H_MM_A } = PAYMENT_PROCESS;
const { DELIVERY } = DISPOSITION_KEYS || {};
const { MENU_PAGE, HELP } = ROUTE_URL || {};
const { DELIVERY_FEE, RESTAURANT_HANDLING_CHARGE, HOURS_TEXT } = TRANSLATE_MAPPING_KEY;

export const signoutAnalytics = async userId => {
  await AnalyticsService.registerEvent(
    'signOutSuccess',
    {
      '%event%': 'signOutSuccess',
      '%userID%': userId || 'NA',
    },
    getDataLayer,
  );
};
export const updateCommunicationPreferencesAnalytics = selectedPreferences => {
  AnalyticsService.registerEvent(
    'updateCommunicationPreferences',
    {
      '%event%': 'updateCommunicationPreferences',
      '%marketingEmails%': selectedPreferences.find(item => item === 'email') ? 'true' : 'false',
      '%receiveSMS%': selectedPreferences.find(item => item === 'sms') ? 'true' : 'false',
      '%editorAndCustomerFavourites%': 'NA',
      '%newsAndAnnouncements%': 'NA',
      '%doNotSellInformation%': 'NA',
    },
    getDataLayer,
  );
};

export const fillDaysList = () => {
  let daysInMonth = [];
  for (let i = 1; i <= 31; i++) {
    daysInMonth.push(i);
  }
  return daysInMonth;
};

export const monthList = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const fillYearsList = minAgeRequirement => {
  let currentYear = new Date().getFullYear();
  let validYears = [];
  for (let birthYears = currentYear - minAgeRequirement; birthYears >= currentYear - 122; birthYears--) {
    validYears.push(birthYears);
  }
  return validYears;
};

export const restrictUserToCharacter = (event, validateNameRegex) => {
  const matches = validateNameRegex.test(event.key);
  if (!matches) {
    event.preventDefault();
  }
  return matches;
};

export const restrictUserToNumber = event => {
  const matches = NUMBER_ONLY_REGEX.test(event.key);
  if (!matches) {
    event.preventDefault();
  }
  return matches;
};

export const validateName = (name, validateNameRegex) => {
  return validateNameRegex?.test(String(name).toLowerCase());
};

export const validateLastName = (name, validateLastNameRegex) => {
  return validateLastNameRegex?.test(String(name).toLowerCase());
};

export const validateEmail = (email, validateEmailRegex) => {
  return validateEmailRegex?.test(String(email).toLowerCase());
};

export const validatePhone = (phone, phoneNumberRegex) => {
  return phoneNumberRegex?.test?.(phone?.replace?.(/ /g, ''));
};

export const validatePostalCode = (postalCode, postalCodeRegex) => {
  const regex = postalCodeRegex;
  return regex.test(String(postalCode));
};

export const formatPhoneNumber = (value, pattern, splitPattern) => {
  if (!value) {
    return value;
  }
  const phoneNumber = value;
  const phoneNumberLength = phoneNumber.length;
  if (phoneNumberLength <= 4) {
    return phoneNumber;
  }

  if (phoneNumberLength <= 7) {
    return `${phoneNumber.slice(0, 4)} ${phoneNumber.slice(4)}`;
  }
  return phoneNumber.replace(pattern, splitPattern);
};

export const formatPostalCode = (postalCode, postalCodeFormatNumber, postalCodesLength, postalCodeSeparator) => {
  if (!postalCode || postalCode.substr(postalCodeFormatNumber, 1) === '-') {
    return postalCode;
  }
  const postalCodeLength = postalCode.length;
  if (postalCodeLength <= postalCodeFormatNumber) {
    return postalCode;
  } else {
    return `${postalCode.slice(0, postalCodeFormatNumber)}${
      postalCodeSeparator ? postalCodeSeparator : ' '
    }${postalCode.slice(postalCodeFormatNumber, postalCodesLength)}`;
  }
};
export const getValidateDateWithRange = (month, day, year, minAgeRequirement) => {
  let monthIndex = monthList.indexOf(month);
  let date = new Date(year, monthIndex, day);
  let startDateYear = fillYearsList(minAgeRequirement)?.[0];
  let endDateYear = fillYearsList(minAgeRequirement)?.[fillYearsList(minAgeRequirement)?.length - 1];
  let firstDay = new Date(startDateYear, new Date().getMonth(), new Date().getDate());
  let lastDay = new Date(endDateYear, new Date().getMonth(), new Date().getDate());
  return firstDay >= date && lastDay <= date;
};
export const dobValidation = (day, month, year, minAgeRequirement) => {
  let monthIndex = monthList.indexOf(month);
  let datefound = false;
  let currentYear = new Date().getFullYear();
  let errObj;
  let date = new Date(year ?? currentYear, monthIndex, 1);
  let isValidDate = getValidateDateWithRange(month, day, year, minAgeRequirement);
  if (day && month && year && isValidDate) {
    while (date.getMonth() === +monthIndex) {
      if (date.getDate() === +day) {
        datefound = true;
      }
      date.setDate(date.getDate() + 1);
    }
    errObj = {
      isValidDOB: datefound,
      isDayNotValid: !datefound,
      dobErrorMsg: ACCOUNT_SETTINGS.DATE_MONTH_ERROR_TEXT,
    };
  } else {
    var dateToday = new Date();
    var lastDay = new Date(dateToday.getFullYear(), monthIndex + 1, 0);
    // eslint-disable-next-line eqeqeq
    if (getMonthDateLastDay({ day, lastDay, monthIndex, year })) {
      errObj = {
        isValidDOB: false,
        isDateNotInRange: !isValidDate,
        isDayFromLeapYearMonth: getDayFromLeapYearMonth(monthIndex, year),
        isDaySelected: getDaySelected(day),
        isMonthSelected: getMonthSelected(month),
        isYearSelected: getYearSelected(year),
        dobErrorMsg: getdobErrorMsg(isValidDate, year),
      };
    } else {
      errObj = {
        isValidDOB: false,
        isDaySelected: false,
        isMonthSelected: false,
        isYearSelected: year || false,
        isDayNotValid: true,
        dobErrorMsg: ACCOUNT_SETTINGS.DATE_MONTH_ERROR_TEXT,
      };
    }
  }
  return errObj;
};

export const isValidEditAccountForm = formData => {
  const {
    firstNameText,
    lastNameText,
    phoneNoText,
    postalCode,
    isValidDOB,
    postalCodeRegex,
    phoneNumberRegex,
    isEnablePostalCodeSignUp,
    postalCodeFormatNumber,
    validateNameRegex,
    validateLastNameRegex,
    isPostalCodeMandtoryField,
    email,
    validateEmailRegex,
    isEmailDisabled,
    isDOBMandatoryField,
    customerInfo,
  } = formData;

  if (
    !firstNameText ||
    !validateName(firstNameText, validateNameRegex) ||
    checkRestrictedWords(RESTRICTED_BAD_WORDS, firstNameText)
  ) {
    return false;
  }

  if (
    !lastNameText ||
    !validateLastName(lastNameText, validateLastNameRegex) ||
    checkRestrictedWords(RESTRICTED_BAD_WORDS, lastNameText)
  ) {
    return false;
  }

  if (!validatePhone(phoneNoText, phoneNumberRegex)) {
    return false;
  }

  if (!customerInfo?.day && !customerInfo?.month && !customerInfo?.year && isDOBMandatoryField) {
    return false;
  }

  if (!isValidDOB) {
    return false;
  }

  if (email && !validateEmail(email, validateEmailRegex)) {
    return false;
  }

  if (postalCode && !validatePostalCode(postalCode, postalCodeRegex)) {
    return false;
  }

  if ((!isPostalCodeMandtoryField && !postalCode) || (!isEmailDisabled && !email)) {
    return true;
  } else if (isEnablePostalCodeSignUp && !postalCode && formatPostalCode(postalCode, postalCodeFormatNumber)) {
    return false;
  }
  return true;
};

export const checkRestrictedWords = (list, value) => {
  return list.some(word => word?.toLowerCase() === value?.toLowerCase());
};
export const getHelp = history => {
  history?.push(HELP);
};

export const goToMenuPage = history => {
  history?.push(MENU_PAGE);
};

export const getPastMonthYear = number => {
  const monthNames = ['01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12'];
  const monthYear = [];
  const today = new Date();
  const todaysMonth = today.getMonth() + 1;
  let d;
  let month;

  for (let i = number; i > 0; i -= 1) {
    d = new Date(today.getFullYear(), todaysMonth - i, 1);
    month = monthNames[d.getMonth()];
    let year = today.getFullYear();
    if (month > todaysMonth) {
      year = year - 1;
    }
    monthYear.push({ yearMonth: `${year}-${month}` });
  }
  return monthYear;
};

export const getDescription = data => {
  const modGroups = get(data, 'modGrps', []);
  const modifiersDetails = modGroups?.map(o => o?.modifiers)?.flat();
  return modifiersDetails?.map(item => `${item?.quantity}x ${item?.name}`)?.join();
};

/* config for view receipt presentation */
export const viewReceiptConfig = (disposition, isMobile) => {
  if (disposition?.toUpperCase() === DELIVERY) {
    return {
      shouldShowDeliveryAddress: true,
      shouldShowDirection: false,
      shouldShowInstructions: true,
      additionalChargeLinesText: DELIVERY_FEE,
      shouldShowHoursBesideStore: getTernaryValue(isMobile, false, true),
    };
  } else {
    return {
      shouldShowDeliveryAddress: false,
      shouldShowDirection: true,
      shouldShowInstructions: false,
      additionalChargeLinesText: RESTAURANT_HANDLING_CHARGE,
      shouldShowHoursBesideStore: false,
    };
  }
};
/* create order receipt accordion */
/* istanbul ignore next */
export const createAccordion = (item, currencySymbol, currencyConversionFactor) => {
  const qty = item?.reduce((acc, curr) => acc + curr?.quantity, 0);
  return (
    <Accordian isBlackArrow={true} header={qty + ' ITEM' + (qty > 1 ? 'S' : '')} className='billDropDown'>
      {item?.map((data, idx) => (
        <div key={idx} className='billDescription'>
          <div id='food-item-container'>
            <span className='number'>{data?.quantity}</span>
            <span id='food-name-title'> {data?.item?.name}</span>
            <div className='secondary-description'>{getDescription(data?.item)}</div>
          </div>
          <div className='ItemPrice foodName'>
            {currencySymbol}
            {currencyConversion(data?.item?.quantity * data?.item?.unitPrice, currencyConversionFactor)}
          </div>
        </div>
      ))}
    </Accordian>
  );
};

/* show store operating hours in order receipt screen */
export const showHoursText = storesWithStatus => {
  const optHrs = storesWithStatus?.[0]?.summary?.timings?.operatingHours;
  let fromTime;
  let toTime;
  let finalArr;
  const date = new Date();
  const day = date?.getDay();
  if (optHrs?.[0]?.day === EVERYDAY) {
    finalArr = optHrs;
  } else {
    finalArr = optHrs?.filter(item => item?.day?.toUpperCase() === DAYS[day]?.toUpperCase());
  }
  fromTime = finalArr?.[0]?.availableHours?.from;
  toTime = finalArr?.[0]?.availableHours?.to;
  return (
    fromTime &&
    toTime &&
    `${translateWithI18Next(HOURS_TEXT)} ${parseInvalidDateAndFormat(
      fromTime,
      HH_MM,
      H_MM_A,
    )}-${parseInvalidDateAndFormat(toTime, HH_MM, H_MM_A)}`
  );
};

export const getFilteredItemsFavOrders = (foodLineItems, addHope, carryBag) => {
  let filteredFoodLine = foodLineItems;
  if (foodLineItems?.length > 0) {
    filteredFoodLine = foodLineItems?.filter(foodItem => foodItem?.name !== addHope && foodItem?.name !== carryBag);
  }
  return filteredFoodLine;
};
/* toast msg shown after adding order as favorite */
/* istanbul ignore next */
export const modifytoastmessage = (ORDER_ADDED_TO_FAVOURITE, FAVOURITES) => {
  const message = translateWithI18Next(ORDER_ADDED_TO_FAVOURITE);
  const favoritesText = translateWithI18Next(FAVOURITES);
  const favoritesTextLine = `<u>${favoritesText}</u>`;
  const replacedMsg = message?.replace('Favourites', favoritesTextLine);
  return parse(String(replacedMsg));
};

/* function to show or hide set location banner for mobile device*/
export const showHideBanner = isMobile => {
  let accountMenuArray = ['order-history-global', 'addresses-global', 'favourite-orders-global'];
  let bannerDiv = document?.getElementById('kfc-banner-image');
  let isElementInDOM = accountMenuArray?.some(elm => document?.getElementById(elm));
  if (isMobile && isElementInDOM && bannerDiv) {
    bannerDiv.style.display = 'none';
  } else if (bannerDiv) {
    bannerDiv.style.display = 'flex';
  }
};

export const reOrderAddToCartAnalytic = async (itemsObj, userType, currency, value, dispositionType) => {
  await AnalyticsService.registerEvent(
    events?.add_to_cart?.name,
    {
      data: {
        event: ANALYTICS_SHARED_CONSTANTS.ADD_TO_CART,
        userType: userType,
        disposition: capitalizeDispositionType(dispositionType),
        ecommerce: {
          currency: currency,
          value: value,
          items: itemsObj,
        },
      },
    },
    getDataLayer,
  );
};

export const reOrderAnalytic = async (userType, orderName = '') => {
  await AnalyticsService.registerEvent(
    events?.reorder?.name,
    {
      data: {
        event: REORDER_CONSTANTS?.REORDER,
        userType: userType,
        orderName: orderName,
      },
    },
    getDataLayer,
  );
};

export const checkUpdatedInfo = (updatedData, basicInfoObj, profileInfo) => {
  const {
    email: updatedEmail,
    firstName: updatedFirstname,
    lastName: updatedLastname,
    phone: updatedPhone,
  } = updatedData;
  const { email, firstName, lastName, phone } = basicInfoObj;
  return (
    updatedEmail !== email ||
    updatedFirstname !== firstName ||
    updatedLastname !== lastName ||
    updatedPhone.split(' ').join('') !== phone ||
    !isEmpty(profileInfo?.birthDay) ||
    !isEmpty(profileInfo?.birthYear) ||
    !isEmpty(profileInfo?.birthMonth)
  );
};
export const SUBSCRIPTION_TYPES = Object.freeze({
  emailSubscribed: 'emailSubscribed',
  smsSubscribed: 'smsSubscribed',
  pushSubscribed: 'pushSubscribed',
  snoozeModeSubscribed: 'snoozeModeSubscribed',
  globalOptOut: 'globalOptOut',
});

export const getCommPreferencesViewModel = (content, data) => {
  const subscriptions =
    content?.map(item => {
      const subscriptionCode = item.fields?.code;
      return {
        code: subscriptionCode,
        title: getContentField(content, subscriptionCode, 'title'),
        subTitle: getContentField(content, subscriptionCode, 'subValue'),
        copy: getContentField(content, subscriptionCode, 'bodyCopy'),
        status: {
          checkedLabel: translateWithI18Next(getYesLabel(subscriptionCode)),
          unCheckedLabel: translateWithI18Next(getNoLabel(subscriptionCode)),
          isChecked: data?.[subscriptionCode],
        },
      };
    }) || [];

  subscriptions.push({
    code: SUBSCRIPTION_TYPES.globalOptOut,
    subTitle: translateWithI18Next(TRANSLATE_MAPPING_KEY.PREFERENCES_UNSUBSCRIBE_ALL),
    status: {
      checkedLabel: translateWithI18Next(getYesLabel(SUBSCRIPTION_TYPES.globalOptOut)),
      unCheckedLabel: translateWithI18Next(getNoLabel(SUBSCRIPTION_TYPES.globalOptOut)),
      isChecked: data?.globalOptOut,
    },
  });

  return subscriptions;
};

const getYesLabel = code => {
  const { YES, PREFERENCES_ON } = TRANSLATE_MAPPING_KEY;
  switch (code) {
    case SUBSCRIPTION_TYPES.emailSubscribed:
      return YES;
    case SUBSCRIPTION_TYPES.smsSubscribed:
      return YES;
    case SUBSCRIPTION_TYPES.pushSubscribed:
      return PREFERENCES_ON;
    case SUBSCRIPTION_TYPES.snoozeModeSubscribed:
      return PREFERENCES_ON;
    case SUBSCRIPTION_TYPES.globalOptOut:
      return YES;
    default:
      return YES;
  }
};

const getNoLabel = code => {
  const { NO, PREFERENCES_OFF } = TRANSLATE_MAPPING_KEY;
  switch (code) {
    case SUBSCRIPTION_TYPES.emailSubscribed:
      return NO;
    case SUBSCRIPTION_TYPES.smsSubscribed:
      return NO;
    case SUBSCRIPTION_TYPES.pushSubscribed:
      return PREFERENCES_OFF;
    case SUBSCRIPTION_TYPES.snoozeModeSubscribed:
      return PREFERENCES_OFF;
    case SUBSCRIPTION_TYPES.globalOptOut:
      return NO;
    default:
      return NO;
  }
};

const getContentField = (content, code, fieldName) => {
  return content?.filter(c => c.fields.code === code)[0]?.fields?.[fieldName] ?? '';
};

export const allMonths = dayjs.monthsShort();

export const monthText = num => {
  return new Date(2000, num - 1).toLocaleString('default', {
    month: 'long',
  });
};

export const getAllDatesInMonth = month => {
  const today = new Date(); // current date
  const end = new Date(today.getFullYear(), month + 1, 0).getDate(); // end date of month
  const result = [];
  for (let i = 1; i <= end; i += 1) {
    result.push(i < 10 ? `0${i}` : i.toString());
  }
  return result;
};
