const PAYMENT_PROCESS = {
  //SaveOrder
  SAVE_ORDER_NAME_ERROR: 'OccasionNameValidation',
  FAVORITES_ORDERS_REGEX: /^(?!\s)[A-Za-z0-9 ]{1,50}$/,

  // OrderConfirmScreen
  CURRENT_ORDER: 'currentOrder',
  PROCESSING: 'processing',
  PROCESSING_YOUR: 'processingYour',
  ORDER_READY_IN_JUST: 'orderReadyInJust',
  SECOND: 'aSecondText',
  CONFIRMING_YOUR_ORDER: 'confirmYourOrder',
  READY_IN_JUST_A_SECOND: 'readyInJustASecond',

  // orderDetailModal
  YOUR_ORDER: 'yourOrder',
  ORDER: 'order#',
  COMPLETED: 'completed',
  DELIVER_TO: 'deliveryTo',
  PAYMENT: 'payment',
  ORDER_FROM: 'orderFrom',
  GET_DIRECTION: 'getDirection',
  SUB_TOTAL: 'subTotal',
  DISCOUNT: 'discount',
  TOTAL: 'total',
  ORDERS: 'order',

  // OrderReceived
  YOUR_SET: 'YOU’RE ALL SET',
  DELEIVERED_AROUND: ' will be delivered around',
  PICKUP_AROUND: 'willBeReadyForPickup',
  READY_FOR_PICKUP: 'readyForPickup',
  NAME_ORDER_NUMBER: 'GIVE US YOUR NAME OR ORDER NUMBER',
  WHEN_YOURE_HERE: 'WHEN YOU’RE HERE.',
  SAVE_YOUR_ORDERS_AND_CHECKOUT_FASTER: 'SAVE YOUR ORDERS AND CHECKOUT FASTER',
  SAVED_TO_FAVORITE_MENU: 'Saved this order in My Favorite Menu.',
  CREATE_AND_ACCOUNT_SAVE_ORDERS: 'CREATE AN ACCOUNT TO SAVE YOUR ORDERS AND',
  CHECKOUT_FASTER: 'CHECKOUT FASTER',
  SAVE_THIS_Order: 'Save This Order',
  ORDER_FROM_CAPS: 'ORDER FROM',
  VIEW_MORE: 'View More Details',
  BACK_TO_CART: 'backToCart',
  THAT_AINT_RIGHT: 'thatNotRight',
  FAILED_MSG: 'unsuccessfulPayment',
  FEEDBACK_EMAIL: 'FeedbackEmail',
  PHONE_NUMBER: 'phoneNumber',
  BACK_TO_CART_MSG: 'GoBackToCart',
  ORDER_ACCEPTED: 'orderAccepted',
  SIGN_UP: 'signAndSaveOrder',
  RESTAURANT_HANDLING_CHARGE: 'cartIncluTax',
  READY_FOR_DINEIN: 'readyForDineIn',
  DINEIN_AROUND: 'dineInText',
  PICKUP: 'PICKUP',
  DELIVERY: 'DELIVERY',
  ONLY_IMAGE: 'Only image',
  DELIVERY_FEE: 'deliveryFee',
  DISCOUNT_TEXT: 'DiscountText',

  // SAVEorder
  THANKYOU: 'saveOrderThankYou',
  WE_SAVED: 'weSaved',
  GIVE_A_NAME: 'giveAName',
  SCHEDULE_ORDER: 'savedOrderScheduleOrder',
  ORDER_DETAIL: 'orderDetails',
  SCHEDULED: 'scheduled',
  GST: 'cartGst',
  largeCarryBagText: 'largeCarryBag',
  addHopeText: 'addhope',
  SEARCH_IN_CART: 'SEARCH_IN_CART',
  HH_MM: 'hh mm',
  HH_MM_A: 'hh:mma',
  H_MM_A: 'h:mma',
  TOTAL_GST_INCLUDED: 'gstIncluded',
  REORDER_FAVOURITES: 'reorderFavourites',
  CREATE_KFC_ACCOUNT: 'createKfcAccount',
  INVALID_MERCHANT_ERROR_CODE: '901',
  INVALID_MERCHANT_ACCOUNT: 'invalidMerchantAccount',
};
export default PAYMENT_PROCESS;
