import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { removeCouponAction } from '@kfc-global/react-shared/redux/Actions/CartAction';
import OffersCoupon from 'organisms/CartPage/Presentation/ReusableComponents/OffersCoupon';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
import Constants from './Constants/checkoutConstants';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import {
  getAddHopeItem,
  getAndCheck,
  getCarryBagItem,
  getFilteredItems,
  getValueOrDefaultArray,
  getValueOrEmptyString,
  getTernaryValue,
  safeUpperCase,
} from 'common/utilities/utils';

export const displayPrice = (amount, factors) => {
  const { currencySymbol, currencyConversionFactor } = factors;
  if (currencySymbol && currencyConversionFactor && amount) {
    return `${currencySymbol}${(amount / currencyConversionFactor).toFixed(2)}`;
  }
  return 'NA';
};

const PriceSummary = props => {
  const { cartReducer = {}, tenantReducer = {}, userParams = {}, type } = props;
  const { CircleCloseButton } = IMAGE_PATH;
  const { tenantId, addHope, carryBag, currencyConversionFactor } = tenantReducer;
  const { cartData = {} } = cartReducer;
  const bucketId = cartData?.id;
  const foodLines = getValueOrDefaultArray(cartData?.foodLines);
  const [quantity, setQuantity] = useState(0);
  const subTotal = cartData?.subtotal;
  const taxAmounts = cartData?.inclusiveTaxTotal;
  const additionalCharges = cartData?.additionalChargeLines || [];
  const discountAmounts = cartData?.discountLines || [];

  const dispatch = useDispatch();

  const { IS_TAKEAWAY, CHECKOUT_SUMMARY, SEARCH_IN_CART, largeCarryBagText, addHopeText } = Constants;

  const { QUANTITY_TEXT, SUBTOTAL, GST, ADDITIONAL_TAX, QUANTITYS_TEXT } = CHECKOUT_SUMMARY;

  const [addHopeDataExistsId, setAddHopeDataExistsId] = useState('');
  const [carryBagDataExistsId, setCarryBagDataExistsId] = useState('');
  const [addHopeDataPrice, setAddHopeDataPrice] = useState(0);
  const [carryBagDataPrice, setCarryBagDataPrice] = useState(0);

  const removeCouponHandler = couponCode => {
    dispatch(
      removeCouponAction({
        basketId: bucketId,
        tenantId,
        couponCode,
      }),
    );
  };

  useEffect(() => {
    let addHopeCarryBag = getAndCheck(addHope, carryBag);
    if (addHopeCarryBag) {
      let addHopeDataExists = foodLines.length
        ? getAddHopeItem(foodLines, addHope, currencyConversionFactor, SEARCH_IN_CART)
        : {};
      let addHopeId = getValueOrEmptyString(addHopeDataExists?.id);
      setAddHopeDataExistsId(addHopeId);
      if (addHopeId) {
        let lineItem = foodLines.find(i => i.id === addHopeId);
        setAddHopeDataPrice(displayPrice(lineItem.amount, tenantReducer));
      }

      let carryBagDataExists = foodLines.length
        ? getCarryBagItem(foodLines, carryBag, currencyConversionFactor, SEARCH_IN_CART)
        : {};
      let addCBId = getValueOrEmptyString(carryBagDataExists?.id);
      setCarryBagDataExistsId(addCBId);
      if (addCBId) {
        let lineItem = foodLines.find(i => i.id === addCBId);
        setCarryBagDataPrice(displayPrice(lineItem.amount, tenantReducer));
      }
      let filteredFoodLineData = getFilteredItems(foodLines, addHope, carryBag);
      setQuantity(filteredFoodLineData?.reduce((acc, food) => +acc + +food?.quantity, 0));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [foodLines, addHope, carryBag]);

  return (
    <div className='checkout-summary d-flex flex-column' id='checkout-summary' data-testid='checkout-summary-test'>
      <h2 className='checkout-summary-item-count'>
        {quantity}{' '}
        {getTernaryValue(quantity === 1, translateWithI18Next(QUANTITY_TEXT), translateWithI18Next(QUANTITYS_TEXT))}
      </h2>
      <div className='checkout-summary-content'>
        <div className='flexBetweens checkout-summary-content-item'>
          <div className='checkout-summary-content-item-name'>{translateWithI18Next(SUBTOTAL)}</div>
          <div className='checkout-summary-content-item-value'>{displayPrice(subTotal, tenantReducer)}</div>
        </div>

        <div className='flexBetweens checkout-summary-content-item'>
          <div className='checkout-summary-content-item-name'>{translateWithI18Next(GST)}</div>
          <div className='checkout-summary-content-item-value'>{displayPrice(taxAmounts, tenantReducer)}</div>
        </div>

        {!!additionalCharges.length &&
          additionalCharges.map((item, idx) => {
            return (
              <div key={idx} className='flexBetweens checkout-summary-content-item'>
                <div className='checkout-summary-content-item-name'>{translateWithI18Next(ADDITIONAL_TAX)}</div>
                <div className='checkout-summary-content-item-value'>{displayPrice(item.amount, tenantReducer)}</div>
              </div>
            );
          })}
        {!!discountAmounts.length &&
          discountAmounts.map((item, idx) => {
            return (
              <div key={idx} className='flexBetweens checkout-summary-content-item'>
                <div className='checkout-summary-content-item-name d-flex'>
                  <span className='checkout-discount-code'>Discount ({item.couponCode})</span>
                  <span
                    className='checkout-discount-clear-icon'
                    data-testid={`remove-coupon-handler-${item?.couponCode}`}
                    onClick={() => removeCouponHandler(item?.couponCode)}
                  >
                    <img src={CircleCloseButton} alt='Circle Close Button' />
                  </span>
                </div>
                <div className='checkout-summary-content-item-value'>- {displayPrice(item.amount, tenantReducer)}</div>
              </div>
            );
          })}
        {safeUpperCase(type) === IS_TAKEAWAY && carryBagDataExistsId && (
          <div className='flexBetweens checkout-summary-content-item' data-testid='checkout-carry-bag-text'>
            <div className='checkout-summary-content-item-name'>{translateWithI18Next(largeCarryBagText)}</div>
            <div className='checkout-summary-content-item-value'>{carryBagDataPrice}</div>
          </div>
        )}
        {Boolean(addHopeDataExistsId) && (
          <div className='flexBetweens checkout-summary-content-item' data-testid='checkout-add-hope-text'>
            <div className='checkout-summary-content-item-name'>{translateWithI18Next(addHopeText)}</div>
            <div className='checkout-summary-content-item-value'>{addHopeDataPrice}</div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PriceSummary;
