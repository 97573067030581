import { useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';
import { AdyenCheckout, Dropin } from '@adyen/adyen-web/auto';
import { useHistory } from 'react-router-dom';
import '@adyen/adyen-web/styles/adyen.css';
import { PAYMENT_TENDER_TYPES, TRANSACTION_ID, Config } from 'common/constants/SharedConstants';
import PAYMENT_CONSTANTS from '../constants/PaymentConstants';
import {
  redirectToOrderProcessingPage,
  redirectToPaymentFailurePage,
  navigateToErrorPage,
} from 'common/utilities/utils';
import {
  adyenPaymentMethodsRequest,
  removeAdyenStoredPaymentsRequest,
  removeAdyenStoredPaymentsReset,
  submitAdyenPaymentRequest,
} from '@kfc-global/react-shared/redux/Actions/AdyenPaymentAction';
import { config } from '@kfc-global/react-shared/config/config.utils';
import { REACT_APP_ADYEN_CLIENT_KEY } from '@kfc-global/react-shared/config/config.constants';
import { getGenerateClientTokenLoadingTemplate } from './templates/PaymentViewTemplates';
import isObject from 'lodash/isObject';
import {
  getAdyenPaymentTenderType,
  getAdyenPaymentsEnvironment,
  additionalAdyenDetails,
  submitAdyenAdditionData,
  getAidenPostData,
  getOperatingSystem,
} from 'organisms/Payment/utils/AdyenPaymentUtils';
import { useAppDispatch } from '@kfc-global/react-shared/redux/Store/hooks';
import { setPWAstorage } from '@kfc-global/react-shared/redux/Slices/PwaStorageSlice';

export const getAdyenPaymentTenderName = paymentMethod => {
  return paymentMethod?.brand?.toLowerCase()?.includes(PAYMENT_TENDER_TYPES.GOOGLE_PAY.toLowerCase())
    ? PAYMENT_TENDER_TYPES.GOOGLE_PAY
    : getAdyenPaymentTenderType(paymentMethod);
};

const AdyenPaymentView = props => {
  const { checkoutDetails, updateCheckoutDetails } = props;
  const {
    countryCode,
    customerId,
    currencyCode,
    currencyFormat,
    orderId,
    paymentMethod,
    storedPaymentId,
    userFirstName,
    userLastName,
    userPhone,
    userEmail,
    total,
    tenantId,
    dispatch,
  } = checkoutDetails;

  const history = useHistory();
  const { ADYEN_PAYMENTS_VERIFICATION_API_RESULT, ADYEN_PAYMENT_TYPE } = PAYMENT_CONSTANTS;
  const { AUTHORISED } = ADYEN_PAYMENTS_VERIFICATION_API_RESULT;
  const paymentMethodRef = useRef();
  const { adyenPaymentMethodsLoading, adyenPaymentMethodsResponse, removeStoredAdeynPaymentResponse } = useSelector(
    data => data.adyenPaymentReducer,
  );
  const userParams = useSelector(state => state?.appStateReducer?.userStatus);
  const sliceDispatch = useAppDispatch();
  let isContinuePaymentActive = false;
  const paymentConfirmedRef = useRef(false);
  /* To retrieve Adyen payment methods */
  useEffect(() => {
    if (!adyenPaymentMethodsLoading) {
      dispatch(
        adyenPaymentMethodsRequest({
          tenantId,
          customerId,
          postBody: {
            orderId,
            countryCode,
            environment: getAdyenPaymentsEnvironment(),
            isIos: getOperatingSystem() === Config.MAC_OS || getOperatingSystem() === Config.IOS ? true : false,
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  /* To Load Adyen dropin */
  useEffect(() => {
    if (adyenPaymentMethodsResponse?.errors?.errorCode) {
      navigateToErrorPage(history, adyenPaymentMethodsResponse?.errors);
      return;
    }
    adyenPaymentMethodsResponse && checkoutCreation();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [adyenPaymentMethodsResponse]);

  useEffect(() => {
    checkoutDetails?.isUpdatedOrderActionRequest && updateCheckoutDetails({ dispatchOrderAction: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isUpdatedOrderActionRequest]);

  /* after order-valdiation got success based on the response dispatching submit adyen payment methods */
  useEffect(() => {
    if (checkoutDetails?.isCreateOrderValidateCompleted) {
      const tenderType = getAdyenPaymentTenderType(paymentMethod);
      dispatch(
        submitAdyenPaymentRequest({
          tenantId,
          customerId,
          postBody: getAidenPostData(checkoutDetails, userParams),
          paymentInitiation: tenderType === PAYMENT_TENDER_TYPES?.CREDIT_CARD,
          action: checkoutDetails?.action, //only for UTC
          /* based on the response from submitAdyenPaymentRequest triggering handleAction method to onAdditionalDetails */
          successCallback: submitAdyenResponse => {
            const responseSubmit = submitAdyenResponse?.response
              ? JSON.parse(submitAdyenResponse?.response)
              : submitAdyenResponse;
            if (responseSubmit?.resultCode === AUTHORISED) {
              paymentConfirmedRef.current = true;
              redirectToOrderProcessingPage(props);
            } else if (responseSubmit?.action) {
              const _data = responseSubmit?.action?.data;
              if (_data && !isObject(_data)) {
                responseSubmit.action.data = JSON.parse(_data);
              }
              updateCheckoutDetails({
                transactionDetails: submitAdyenResponse?.transactionId,
                paymentData: responseSubmit?.action?.paymentData,
              });
              sliceDispatch(setPWAstorage({ key: TRANSACTION_ID, value: submitAdyenResponse?.transactionId }));
              window.dropin?.handleAction(responseSubmit?.action);
            } else {
              redirectToPaymentFailurePage(props);
            }
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.isCreateOrderValidateCompleted]);

  /* To Disable payment method when onDisableStoredPaymentMethod is called */
  useEffect(() => {
    if (storedPaymentId) {
      dispatch(
        removeAdyenStoredPaymentsRequest({
          queryProps: { storedPaymentId },
          postBody: {
            orderId,
          },
          tenantId,
          customerId,
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, storedPaymentId]);

  useEffect(() => {
    if (removeStoredAdeynPaymentResponse) {
      dispatch(removeAdyenStoredPaymentsReset());
      dispatch(
        adyenPaymentMethodsRequest({
          tenantId,
          customerId,
          postBody: {
            orderId,
            countryCode,
            environment: getAdyenPaymentsEnvironment(),
          },
        }),
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, adyenPaymentMethodsRequest, removeStoredAdeynPaymentResponse]);

  useEffect(() => {
    if (checkoutDetails?.transactionDetails && checkoutDetails?.additionalDetails) {
      const postBody = {
        orderId,
        details: checkoutDetails?.additionalDetails,
        transactionId: checkoutDetails?.transactionDetails,
        paymentData: checkoutDetails?.paymentData,
      };
      submitAdyenAdditionData({ tenantId, customerId, postBody, dispatch, AUTHORISED, props });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkoutDetails?.additionalDetails, checkoutDetails?.transactionDetails, checkoutDetails?.paymentData]);

  const waitForPaymentConfirmation = () => {
    return new Promise((resolve, reject) => {
      const interval = setInterval(() => {
        if (paymentConfirmedRef.current) {
          clearInterval(interval);
          resolve();
        }
      }, 500);
    });
  };

  /* created AdyenCheckout instance passed configurations as parameter */
  const checkoutCreation = async () => {
    /* Adyen configuration which will be passed as parameter to AdyenCheckout instance */
    const responseData = adyenPaymentMethodsResponse && JSON.parse(adyenPaymentMethodsResponse?.response);
    const configuration = {
      paymentMethodsResponse: responseData,
      clientKey: config(REACT_APP_ADYEN_CLIENT_KEY),
      locale: currencyFormat,
      environment: getAdyenPaymentsEnvironment(),
      countryCode,
      showPaymentMethods: true,
      amount: {
        value: total,
        currency: currencyCode,
      },
      onSubmit: async (state, dropin, actions) => {
        const { paymentMethod, browserInfo } = state?.data;
        if (paymentMethod) {
          updateCheckoutDetails({
            paymentMethod,
            browserInfo,
            isUpdatedOrderActionRequest: true,
            isClickPlaceOrder: true,
            tender: {
              name: getAdyenPaymentTenderName(paymentMethod),
            },
            placeOrderRequest: {
              request: {
                customer: {
                  id: customerId,
                  firstName: userFirstName?.value,
                  lastName: userLastName?.value,
                  phoneNumber: userPhone?.value,
                  email: userEmail?.value,
                  couponCodes: [],
                  customProperties: null,
                },
              },
            },
            dropin,
          });
        }
        try {
          await waitForPaymentConfirmation();
          if (paymentConfirmedRef.current) {
            //action, order & resultCode are for future work, they are not used in current implementation
            const { action, order, resultCode } = state.data;
            actions.resolve({
              resultCode,
              action,
              order,
            });
          }
        } catch (error) {
          actions.reject();
        }
      },
      /* working on the item still need to get response from onsubmit */
      onAdditionalDetails: async state => {
        additionalAdyenDetails(state, updateCheckoutDetails);
      },
      paymentMethodsConfiguration: {
        card: {
          name: ADYEN_PAYMENT_TYPE.CREDIT_OR_DEBIT,
        },
        googlepay: {
          transactionInfo: {
            checkoutOption: 'COMPLETE_IMMEDIATE_PURCHASE',
          },
        },
      },
      onChange: state => {
        isContinuePaymentActive = state?.isValid;
        updateCheckoutDetails({
          isAdyenCardValid: isContinuePaymentActive,
          isClickContinuePayment: isContinuePaymentActive,
        });
      },
      onError: (error, _component) => {
        if (Object.values(error).includes('CANCEL')) {
          updateCheckoutDetails({
            isCreateOrderValidateCompleted: false,
            isUpdatedOrderActionRequest: false,
            dispatchOrderAction: false,
          });
        }
      },
    };

    /** Due to PayPal version mismatch between and Adyen and Braintree removing paypal instence */
    if (!window.dropin && window?.paypal) {
      delete window.paypal;
    }
    const adyenCheckoutDetails = await AdyenCheckout(configuration);
    window.dropin = new Dropin(adyenCheckoutDetails, {
      showRemovePaymentMethodButton: true,
      onDisableStoredPaymentMethod: (storedPaymentMethodId, resolve) => {
        resolve(updateCheckoutDetails({ storedPaymentId: storedPaymentMethodId }));
      },
      paymentMethodsConfiguration: {
        openFirstPaymentMethod: true,
        showPayButton: true, // to dusplay pay button
        onSelect: state => {
          const { paymentMethod } = state?.data;
          const tenderType = getAdyenPaymentTenderType(paymentMethod);
          const ContinuePaymentBtnState = tenderType === 'credit-card' ? isContinuePaymentActive : false;
          updateCheckoutDetails({
            isAdyenCardValid: ContinuePaymentBtnState,
            isClickContinuePayment: ContinuePaymentBtnState,
          });
        },
      },
    })
      .mount(paymentMethodRef.current)
      ?.update();
    return [adyenCheckoutDetails, window.dropin];
  };

  return (
    <>
      {/* Display Adyen payment */}
      {checkoutDetails?.isClickAddPayment && (
        <div className='payment-info'>
          <div ref={paymentMethodRef}></div>
        </div>
      )}
      {getGenerateClientTokenLoadingTemplate(adyenPaymentMethodsLoading)}
    </>
  );
};

export default AdyenPaymentView;
