import PAYMENT_PROCESS from '../Constants';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { SignUpClick, PostOrderSignInClick } from '../../MenuPDPPage/Presentation/ReusableComponents/PdpAnalytics';
import Accordian from 'atoms/Accordian/Accordian';
import {
  isMobileDevice,
  safeUpperCase,
  getAddHopeItem,
  getValueOrEmptyString,
  getCarryBagItem,
} from '../../../common/utilities/utils';
import { displayPrice } from 'components/checkout/PriceSummary';
import { login } from 'common/utilities/LoginUtils';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';

const {
  ORDERS = '',
  READY_FOR_PICKUP = '',
  DELEIVERED_AROUND = '',
  PICKUP_AROUND = '',
  ORDER_ACCEPTED = '',
  READY_FOR_DINEIN = '',
  DINEIN_AROUND = '',
  PICKUP = '',
} = PAYMENT_PROCESS;

const { TakeawayCompleted, ConfirmedImg } = IMAGE_PATH;

export const windowResized = setMobileFooter => {
  const isMobileView = isMobileDevice(window.innerWidth);
  setMobileFooter(isMobileView);
};

export const signInClick = (OrderReducerData, currencyConversionFactor) => {
  PostOrderSignInClick();
  login();
  SignUpClick({ orderValue: (OrderReducerData?.total / currencyConversionFactor).toFixed(2) || 'NA' });
};

export const saveClick = toggleModal => {
  toggleModal(true);
};

export const Saved = (setNotifySaved, onSave) => {
  setNotifySaved(onSave);
};

export const getDirectionClick = setShowMap => {
  setShowMap(true);
};

export const DeliveryToggle = (OrderReducerData, Time) => {
  return (
    <div>
      <img src={ConfirmedImg} alt='success' />
      <div className='allSet'>{translateWithI18Next(ORDER_ACCEPTED)}.</div>
      <div className='idAndTime'>
        {translateWithI18Next(ORDERS?.toUpperCase())}{' '}
        <span className='red'>
          #{OrderReducerData?.posOrderId === null ? OrderReducerData?.id : OrderReducerData?.posOrderId}{' '}
        </span>
        {translateWithI18Next(DELEIVERED_AROUND.toUpperCase())}
        <div className='red'>{Time}</div>
      </div>
    </div>
  );
};

export const PickupAndDineInToggle = (OrderReducerData, Time, service) => {
  return (
    <div>
      <img src={TakeawayCompleted} alt='success' />
      <div className='allSet'>
        {safeUpperCase(service) === PICKUP
          ? translateWithI18Next(READY_FOR_PICKUP)
          : translateWithI18Next(READY_FOR_DINEIN)}
      </div>
      <div className='idAndTime'>
        {translateWithI18Next(ORDERS)}{' '}
        <span className='red'>
          #{OrderReducerData?.posOrderId === null ? OrderReducerData?.id : OrderReducerData?.posOrderId}{' '}
        </span>
        {safeUpperCase(service) === PICKUP ? translateWithI18Next(PICKUP_AROUND) : translateWithI18Next(DINEIN_AROUND)}
        <div className='red'>{Time}</div>
      </div>
    </div>
  );
};

export const currencyConversion = (amount, currencyConversionFactor) => {
  return (amount / currencyConversionFactor)?.toFixed(2);
};

export const quantity = Item => {
  return Item?.reduce((acc, curr) => acc + curr?.quantity, 0);
};

export const accordianData = (item, currencySymbol, currencyConversionFactor) => {
  const qty = quantity(item);
  return (
    <Accordian header={qty + ' ITEM' + (qty > 1 ? 'S' : '')} className='billDropDown'>
      {item?.map((data, idx) => (
        <div key={idx} className='billDescription'>
          <div id='foodItemContainer'>
            <span className='number'>{data?.quantity}</span>
            <span id='foodNameChild' className='foodName'>
              {' '}
              {data?.item?.name}
            </span>
          </div>
          <div className='ItemPrice foodName'>
            {currencySymbol}
            {currencyConversion?.call(
              this,
              data?.item?.quantity * data?.item?.unitPrice,
              currencyConversionFactor,
            )}{' '}
          </div>
        </div>
      ))}
    </Accordian>
  );
};

export const setAddHopeCarryBagInfo = (
  OrderReducerData,
  addHope,
  carryBag,
  SEARCH_IN_CART,
  setAddHopeObj,
  setCarryBagObj,
  tenantReducer,
) => {
  const { setAddHopeDataExistsId, setAddHopeDataPrice } = setAddHopeObj || {};
  const { setCarryBagDataExistsId, setCarryBagDataPrice } = setCarryBagObj || {};
  const addHopeCarryBag = {
    addHopeDataExistsId: '',
    carryBagDataExistsId: '',
    addHopeDataPrice: '',
    carryBagDataPrice: '',
  };
  const { currencyConversionFactor } = tenantReducer;
  const addHopeDataExists = OrderReducerData?.foodLines?.length
    ? getAddHopeItem(OrderReducerData?.foodLines, addHope, currencyConversionFactor, SEARCH_IN_CART)
    : {};
  addHopeCarryBag.addHopeDataExistsId = getValueOrEmptyString(addHopeDataExists?.id);
  setAddHopeObj && setAddHopeDataExistsId(addHopeCarryBag?.addHopeDataExistsId);
  if (addHopeCarryBag?.addHopeDataExistsId) {
    const lineItem = OrderReducerData?.foodLines?.find(item => item?.id === addHopeCarryBag?.addHopeDataExistsId);
    addHopeCarryBag.addHopeDataPrice = displayPrice(lineItem?.amount, tenantReducer);
    setAddHopeObj && setAddHopeDataPrice(addHopeCarryBag?.addHopeDataPrice);
  }

  const carryBagDataExists = OrderReducerData?.foodLines?.length
    ? getCarryBagItem(OrderReducerData?.foodLines, carryBag, currencyConversionFactor, SEARCH_IN_CART)
    : {};
  addHopeCarryBag.carryBagDataExistsId = getValueOrEmptyString(carryBagDataExists?.id);
  setCarryBagObj && setCarryBagDataExistsId(addHopeCarryBag?.carryBagDataExistsId);
  if (addHopeCarryBag?.carryBagDataExistsId) {
    const lineItem = OrderReducerData?.foodLines.find(item => item?.id === addHopeCarryBag?.carryBagDataExistsId);
    addHopeCarryBag.carryBagDataPrice = displayPrice(lineItem?.amount, tenantReducer);
    setCarryBagObj && setCarryBagDataPrice(addHopeCarryBag?.carryBagDataPrice);
  }
  return addHopeCarryBag;
};
