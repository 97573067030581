import { toast } from 'react-toastify';

export const getToastNotification = (toastText, dismissTimeOut = 4000, position = 'BOTTOM_CENTER', toastId) => {
  toast.dark(
    <div className='img-text-div'>
      <span className={`notifyText notifyText-in`}>{toastText}</span>
    </div>,
    {
      ...(toastId && { toastId }),
      bodyClassName: 'toast-for-favorite',
      hideProgressBar: true,
      position: toast.POSITION[position],
    },
  );
  setTimeout(() => {
    toast.dismiss();
  }, dismissTimeOut);
};
