import HEADER_CONSTANT from '../HeaderConstants/HeaderConstant';
import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import { getDataLayer, currentTimeOfStore } from 'common/utilities/utils';
import events from '@kfc-global/react-shared/analytics/Register';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { subtractMin } from 'common/utilities/dayJsUtils';
import { DATE_FORMATS, ROUTE_URL } from 'common/constants/SharedConstants';
/**
 *
 * @param {*} data - header module data - Object
 */
export const modifyData = ({
  headerData,
  tenantReducerBasic,
  isSpecialEventEnabled = false,
  enableDeliveryMenuOption = false,
} = {}) => {
  const {
    HEADER_LOGO,
    MENU_LINK,
    DEALS_LINK,
    CATERING_LINK,
    EVENT_LINK,
    SIGN_IN_LINK,
    CART_ICON_100_PERCENT,
    CART_ICON_120_PERCENT,
    SUPPORT_LINK,
    DELIVERY_MENU_LINK,
  } = HEADER_CONSTANT;
  const headerContent = {
    kfcLogo: {},
    navLeft: [],
    navRight: [],
    navMobileMenu: {},
    languageObj: {},
  };
  if (headerData?.subHeaderSection) {
    for (const headerItem of headerData.headerSection) {
      const { title = '' } = headerItem.fields;
      const titleUpperCase = title?.toUpperCase()?.trim();
      if (
        titleUpperCase === MENU_LINK ||
        titleUpperCase === DEALS_LINK ||
        titleUpperCase === CATERING_LINK ||
        (titleUpperCase === DELIVERY_MENU_LINK && enableDeliveryMenuOption) ||
        (titleUpperCase === EVENT_LINK && isSpecialEventEnabled)
      ) {
        headerContent.navLeft.push({
          ...modifyNavData(headerItem.fields),
          altText: headerItem?.fields?.linkText,
        });
      } else if (titleUpperCase === SIGN_IN_LINK) {
        const { altText, url } = modifyImageItemData(headerItem?.fields?.icon?.fields);
        headerContent.navRight.push({
          ...modifyNavData(headerItem?.fields),
          title: titleUpperCase,
          altText,
          imageUrl: url,
        });
      } else if (titleUpperCase === CART_ICON_100_PERCENT || titleUpperCase === CART_ICON_120_PERCENT) {
        headerContent.navRight.push({
          ...modifyImageItemData(headerItem?.fields),
          title: titleUpperCase,
          altText: headerItem?.fields?.title,
        });
      } else if (titleUpperCase === HEADER_LOGO) {
        const { url: src, altText: alt } = modifyImageItemData(headerItem?.fields);
        headerContent.kfcLogo = { src, alt };
      } else {
        /* DO NOTHING : THIS IS TO AVOID SONAR RULES */
      }
    }
    for (const subHeaderItem of headerData?.subHeaderSection) {
      const { title = '' } = subHeaderItem?.fields;
      const titleUpperCase = title?.toUpperCase();
      if (titleUpperCase === SUPPORT_LINK) {
        headerContent.navMobileMenu.supportCategory = modifyCategoryData(subHeaderItem?.fields);
      } else {
        /* DO NOTHING : THIS IS TO AVOID SONAR RULES */
      }
    }
  }
  headerContent.languageObj = getAllowedLanguage(tenantReducerBasic);
  return headerContent;
};
/**
 * method to modify header item data stucture
 * @param {*} item nav item
 */
const modifyNavData = item => {
  const { linkText: name, url: linkTo, icon = {}, internalurl: pathname = '' } = item;
  const { fields } = icon;
  const { url: imageUrl = '', altText = '' } = (fields && modifyImageItemData(fields)) || {};
  return { name, linkTo, imageUrl, altText, pathname };
};

/**
 * method to modify Cart Icon item data stucture
 * @param {*} item nav item
 */
const modifyImageItemData = item => {
  const { desktopImage } = item;
  return (desktopImage && getImage(desktopImage)) || {};
};
/**
 * method to get item image url
 * @param {*} image
 */
export const getImage = desktopImage => {
  if (!desktopImage?.fields?.file) {
    return {};
  }
  const { url = '', altText = '' } = desktopImage?.fields?.file;
  return { url, altText };
};
/**
 * method to get store details for 45min screen
 * @param {*} object
 */
export const getStoreDetails = store => {
  const getTimeZone = store?.summary?.timeZoneIANA || store?.summary?.timeZone;
  const storeEndTime = store?.summary?.timings?.operatingHours?.[0]?.availableHours?.to || {};
  return {
    storeCurrentTime: currentTimeOfStore(getTimeZone),
    storeEndTime,
    storeName: store?.summary?.name,
    timeZone: getTimeZone,
  };
};
/**
 * method to calculate 45min duration from store endTime
 * @param {*} object
 */
export const checkTimeDuration = (storeEndTime, storeCurrentTime) => {
  if (!storeEndTime) {
    return;
  }
  const [hour, minute] = storeEndTime?.split(':');
  const endTime = subtractMin(hour, minute, 45, DATE_FORMATS.HHmm);
  return +endTime === +storeCurrentTime;
};

/**
 * method to modify category data
 * @param {*} item
 */
const modifyCategoryData = item => {
  let subCategories = [];
  const { category: categoryHeaderText, links = [] } = item;
  subCategories = links?.map(linkitem => modifySubcategoryData(linkitem?.fields));
  return { categoryHeaderText, subCategories: subCategories };
};
/**
 * method to modify subcategory data
 * @param {*} item
 */
const modifySubcategoryData = item => {
  const { linkText: name, url: linkTo, internalurl, externalurl, icon } = item;
  const iconUrl = icon?.fields?.desktopImage?.fields?.file?.url;
  return { name, linkTo, internalurl, externalurl, icon: iconUrl };
};

/**
 * method to modify language data based on tenantReducer allowed languages
 */
const getAllowedLanguage = basic => {
  let languageObject = {
    name: '',
    linkTo: '',
    url: '',
    type: 'Language Selector',
    options: [],
  };
  const { allowedLanguages } = basic || { allowedLanguages: [{ value: '' }] };
  if (allowedLanguages.length > 0) {
    for (let lang of allowedLanguages) {
      languageObject.options.push(lang?.value);
    }
    return languageObject;
  } else {
    return {};
  }
};

/**
 * Method to set analytics items in the data layer object
 */
export const setAnalyticsData = async analyticsData => {
  analyticsData?.headerItemSelected &&
    (await AnalyticsService.registerEvent(
      events.topNavigationLinks?.name,
      {
        '%event%': 'topNavigationLinks',
        '%navigationLink%': analyticsData.headerItemSelected,
      },
      getDataLayer,
    ));
};

export const openLanguageSelectorModule = (allowedLanguages, LANGUAGE_SELECTOR, userOrderStateDispatch) => {
  allowedLanguages?.length > 1 &&
    userOrderStateDispatch({ type: LANGUAGE_SELECTOR, value: { showLanguageSelector: true } });
};

export const getSecondaryHeadertitle = ({
  checkoutHeader,
  isOrderSummaryHeader,
  dispositionNew,
  isWicodeFinaliseFailed,
  showSavedPaymentTextInHeader,
}) => {
  const { CHECKOUT, TRACK_ORDER, PROCESSING, SAVED_PAYMENT_OPTIONS, VERIFY_NUMBER_TO_PROGRESS } = HEADER_CONSTANT;
  if (checkoutHeader) {
    return translateWithI18Next(CHECKOUT);
  } else if (isOrderSummaryHeader) {
    return translateWithI18Next(dispositionNew?.orderDetails?.type);
  } else if (isWicodeFinaliseFailed) {
    return translateWithI18Next(PROCESSING);
  } else if (showSavedPaymentTextInHeader) {
    return translateWithI18Next(SAVED_PAYMENT_OPTIONS);
  } else if (window?.location?.pathname === ROUTE_URL.OTPSCREEN) {
    return translateWithI18Next(VERIFY_NUMBER_TO_PROGRESS);
  }
  return translateWithI18Next(TRACK_ORDER);
};
