import React, { useContext, useEffect, useState } from 'react';
import clsx from 'clsx';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import {
  getApplicableCouponsAction,
  applyCoupon,
  removeCouponAction,
} from '@kfc-global/react-shared/redux/Actions/CartAction';
import ImageComponent from 'atoms/Image';
import { UserOrderContext } from 'context/context';
import {
  MODAL_HEADER_TEXT,
  MODAL_CONTENT_HEADER,
  MODAL_CONTENT_DESC,
  COUPON_INPUT_LABEL,
  COUPON_APPLY_BUTTON,
  MODAL_MID_HEADER,
  USER_SIGN_IN_TEXT,
  SUCCESS_COUPON_MSG,
  APPLIED,
  INVALIDATED,
  SET_COUPON_ERROR_MESSAGE,
  COUPON_EXPIRE_MSG,
} from '../Constants/CouponConstants';
import { isMobileDevice, filterOutBlackListedCoupons } from 'common/utilities/utils';
import ModalComponent from '../../../molecules/Modal';
import { InputBox } from '../../../atoms/InputBox';
import { ButtonComp } from '../../../atoms/Buttons';
import CouponsList from './CouponsList';
import { toast } from 'react-toastify';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { Config } from 'common/constants/SharedConstants';
import { getErrorMsg } from '../utils/CouponUtils';
import { getCouponModalEmbeddedErrorMgsAnalyticFn } from 'components/checkout/CheckoutFn';
import { login } from 'common/utilities/LoginUtils';
import { setRedeemOffersAndPromotionsAnalyticsData } from 'organisms/OffersAndPromotions/OffersAndPromotionsUtilities/offersAndPromotionsUtils';
import { CART_PAGE_CONSTANTS } from 'organisms/CartPage/Constants/CartPageConstants';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';
const { ANALYTICS_EVENTS } = CART_PAGE_CONSTANTS;

/**
 * The element renders the coupons landing modal with close button
 * @param {*} props
 * @props isOpen: Boolean value to determine if modal is open
 * @props handleClose: Handler function for closing the Modal
 * @returns JSX.Element
 */
export const CouponsModal = props => {
  const { isOpen, handleClose, bucketId } = props;

  const tenantData = useSelector(data => data.tenantReducer);
  const { userOrderStateDispatch } = useContext(UserOrderContext);
  const { tenantId = '', componentOptions = {} } = tenantData;
  /**
   * State to determine if the screen-width falls under Mobile category
   */
  const [isMobile, setIsMobile] = useState(isMobileDevice(window.innerWidth));
  const [couponCode, setCouponCode] = useState('');
  const [couponErr, setcouponErr] = useState('');
  const [showCouponError, setShowCouponError] = useState(false);
  const dispatch = useDispatch();
  const cartReducer = useSelector(data => data.cartReducer);
  const { userStatus = {} } = useSelector(data => data.appStateReducer);
  const { loggedIn = false } = userStatus;
  const { applicableCoupons, couponError, cartData = {}, isCouponLoading = true } = cartReducer;
  const { discountLines = [] } = cartData;
  const filteredBlackListedCouponProp = filterOutBlackListedCoupons(applicableCoupons);
  const { Ellipse, tickWhiteToast } = IMAGE_PATH;
  /**
   * useEffect to dispatch action to get the applicable coupons
   */
  useEffect(() => {
    dispatch(
      getApplicableCouponsAction({
        basketId: bucketId,
        tenantId,
      }),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getCouponModalEmbeddedErrorMgsAnalyticFn(translateWithI18Next(MODAL_HEADER_TEXT), couponErr);
  }, [couponErr]);

  /**
   * useEffect to add and cleanup of window resize event
   */
  useEffect(() => {
    window.addEventListener('resize', windowResized);
    return () => {
      window.removeEventListener('resize', windowResized);
    };
  });

  const inputChangeHandler = e => {
    setCouponCode(e.target.value);
    setcouponErr('');
  };

  const couponApplyHandler = (e, discountCode = '') => {
    e.stopPropagation();
    const btnElm = document.getElementById('coupon-top-div');
    btnElm?.scrollIntoView(true);
    userOrderStateDispatch({
      type: SET_COUPON_ERROR_MESSAGE,
      value: { couponErrorMessage: '' },
    });
    dispatch(
      applyCoupon(
        {
          basketId: bucketId,
          tenantId,
        },
        {
          couponCode: discountCode || couponCode,
        },
      ),
    );
    let coupon = discountCode || couponCode;
    setCouponCode(coupon);
  };

  useEffect(() => {
    const appliedCoupons = discountLines.filter(obj => obj?.status?.name === APPLIED);
    if (appliedCoupons?.length > componentOptions?.coupons?.noOfCoupopnAllowed) {
      dispatch(
        removeCouponAction({
          basketId: bucketId,
          tenantId,
          couponCode: appliedCoupons[0]?.couponCode,
        }),
      );
    }
    if (couponCode && !isCouponLoading && !showCouponError) {
      if (couponError && couponError?.errorCode) {
        setcouponErr(couponError.errors[0]);
      } else if (discountLines?.length && discountLines[discountLines?.length - 1]?.status?.name === APPLIED) {
        handleClose('true');
        toast.dark(
          <div className='img-text-div'>
            <ImageComponent srcFile={tickWhiteToast} />{' '}
            <span className='text'>{translateWithI18Next(SUCCESS_COUPON_MSG)}</span>
          </div>,
          {
            className: 'coupon-toast-position',
            bodyClassName: 'success-coupon-toast',
            hideProgressBar: true,
            position: toast.POSITION.BOTTOM_CENTER,
          },
        );
        localStorage.setItem('expiryMessage', translateWithI18Next(COUPON_EXPIRE_MSG));
        setRedeemOffersAndPromotionsAnalyticsData({
          event: ANALYTICS_EVENTS.REDEEM_OFFER,
          offerTitle: couponCode,
        });
        setTimeout(() => {
          toast.dismiss();
        }, 3000);
      } else if (discountLines?.length && discountLines[discountLines?.length - 1]?.status?.name === INVALIDATED) {
        setcouponErr(getErrorMsg(discountLines[discountLines?.length - 1]));
        setShowCouponError(true);
      }
    } else if (showCouponError) {
      userOrderStateDispatch({
        type: SET_COUPON_ERROR_MESSAGE,
        value: { couponErrorMessage: '' },
      });
      setShowCouponError(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [couponError, discountLines]);

  /**
   * Handler function for resizing of window
   */
  const windowResized = () => {
    setIsMobile(isMobileDevice(window.innerWidth));
  };

  /**
   * To Clear the Coupon Input Field
   */
  const clearSearch = () => {
    setCouponCode('');
    setcouponErr('');
  };

  const checkUser = () => {
    login();
  };

  /**
   * Changing of the props sent to modal component based on screen-width
   */
  const modalProps = isMobile
    ? {
        id: 'CouponsModalPopup',
        role: 'dialog',
        modalTitleId: 'CouponsModalPopup',
        show: isOpen,
        classNames: 'coupons-module-modal',
        showCloseBtn: true,
        closeButtonHandler: handleClose,
        modalTitle: translateWithI18Next(MODAL_HEADER_TEXT),
        autoFocus: true,
        closeDataTestId: 'coupon-close',
        backDataTestId: 'coupon-back',
      }
    : {
        id: 'CouponsModalPopup',
        role: 'dialog',
        modalTitleId: 'CouponsModalPopup',
        show: isOpen,
        classNames: 'coupons-module-modal',
        showCloseBtn: true,
        showBackBtn: true,
        closeButtonHandler: handleClose,
        backButtonHandler: handleClose,
        modalTitle: translateWithI18Next(MODAL_HEADER_TEXT),
        autoFocus: true,
        closeDataTestId: 'coupon-close',
        backDataTestId: 'coupon-back',
      };
  const btnClass = clsx({
    'coupon-text-box': true,
    'error-msg': couponErr,
  });

  return (
    <ModalComponent {...modalProps} data-testid='modal-coupon-landing-page'>
      <div className='coupon-top-box coupon-border' id='coupon-top-div'>
        <div className='coupon-top-content'>
          <div className='coupon-discount-col-1'>
            <h3 data-testid='coupon-content-header' className='coupon-content-header'>
              {translateWithI18Next(MODAL_CONTENT_HEADER)}
            </h3>
            <p data-testid='coupon-content-desc' className='coupon-content-desc'>
              {translateWithI18Next(MODAL_CONTENT_DESC)}
            </p>
          </div>
          <div className='coupon-discount-col-2'>
            <label data-testid='coupon-input-label' className='coupon-input-label' htmlFor='modal-coupon-input'>
              {translateWithI18Next(COUPON_INPUT_LABEL)}
            </label>
            <div className='flex-input-section'>
              <InputBox
                id='modal-coupon-input'
                value={couponCode}
                className={btnClass}
                placeholder='Enter code'
                data-testid='modal-coupon-input-field'
                onChange={inputChangeHandler}
                // onKeyPressHandler={keyPressHandler}
              />
              {couponCode.length > 0 && (
                <button
                  data-testid='coupon-cross-icon'
                  className='discount-button-img'
                  onClick={() => clearSearch()}
                  onKeyPress={() => clearSearch()}
                  aria-label='Clear Discount Code'
                />
              )}
            </div>
            {couponErr && (
              <div role='alert'>
                <img className='' src={Ellipse} alt='invalid' />{' '}
                <span data-testid='coupon-err-msg' className='error-msg error-container-coupon'>
                  {couponErr}
                </span>
              </div>
            )}
          </div>

          <div className='coupon-discount-col-3'>
            <ButtonComp
              aria-label={translateWithI18Next(COUPON_APPLY_BUTTON)}
              id='btn-apply-view'
              data-testid='coupon-apply-btn'
              className='button whiteButton blackBorder coupon-button'
              disabled={!couponCode?.length}
              onClick={e => couponApplyHandler(e, '')}
            >
              {translateWithI18Next(COUPON_APPLY_BUTTON)}
            </ButtonComp>
          </div>
        </div>
      </div>

      <div className='coupon-mid-box'>
        <h3 data-testid='modal-mid-header' className='modal-mid-header'>
          {translateWithI18Next(MODAL_MID_HEADER)}
        </h3>
        {!loggedIn && (
          <span
            onClick={() => checkUser()}
            data-id={Config.HEADER_SIGN_IN_ATTR_DATA_ID}
            data-testid='modal-mid-desc'
            tabIndex='0'
            className='modal-mid-desc'
          >
            {translateWithI18Next(USER_SIGN_IN_TEXT)}
          </span>
        )}
      </div>
      <CouponsList
        data-testid='coupons-list'
        isMobile={isMobile}
        applyCoupon={couponApplyHandler}
        tenantId={tenantId}
        couponsData={filteredBlackListedCouponProp}
        tenantData={tenantData}
      />
    </ModalComponent>
  );
};

CouponsModal.propTypes = {
  isOpen: PropTypes.bool,
  closeModal: PropTypes.func,
};

CouponsModal.defaultProps = {
  isOpen: false,
  closeModal: null,
};

export default CouponsModal;
