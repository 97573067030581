import React from 'react';

/**
 * Close Button Component
 */
export const InputBoxComp = props => {
  const { onKeyPressHandler = null, value = '', inputType = '', ref, ...rest } = props;
  return (
    <input
      data-testid='check-input-box'
      value={value}
      onChange={e => e.preventDefault()}
      onKeyPress={onKeyPressHandler}
      type={inputType}
      {...rest}
    ></input>
  );
};
