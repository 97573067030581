export const GroupTileContainer = props => {
  const { children } = props;
  return (
    <>
      {children?.length > 0 && (
        <div className='group-tile-container' data-testid='group-tile-container'>
          {children.map(config => config)}
        </div>
      )}
    </>
  );
};
