import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { v4 as uuidv4 } from 'uuid';
import { isTimeValid, getValidFormatDatePerYear } from '../utils/CouponUtils';
import { sanitizeContent, getFullImagePath, emptyFunction } from 'common/utilities/utils';
import { config } from '@kfc-global/react-shared/config/config.utils';
import ImageComponent from '../../../atoms/Image';
import { DEFAULT_IMAGE_PATH, TERMS_AND_CONDITIONS, OFFER_APPLIED, REDEEM } from '../Constants/CouponConstants';
import { ButtonComp } from '../../../atoms/Buttons';
import TimerComponent from './TimerComponent';
import { translateWithI18Next } from '@kfc-global/kfc-i18n/lib';
import { TENANT_IMAGE_BASE_URL } from '@kfc-global/react-shared/config/config.constants';
import { IMAGE_PATH } from 'common/ImageConstants/ImagePathUtils';

/**
 *
 * @param {*} props
 * @props data: Datasource to render coupon data in card
 * @props cardType: To determine the type of card (with/without image)
 * @props id: unique id
 * @props tenantId: To build the image src path
 * @returns JSX.Element
 */

const getClassName = (classNames, params, isOpenVal) => {
  return isOpenVal ? `${classNames} ${params}` : `${classNames}`;
};

const CouponCardDesktop = props => {
  const { data, cardType, id, tenantId, applyCoupon, tenantData } = props;
  /**
   * State variable to open and close the terms and conditions
   */
  const [isOpen, toggleTerms] = useState(false);
  const { TimerIcon, Time_ClockDark, ArrowIcon } = IMAGE_PATH;
  /**
   * Method to render cards without image
   * @param {Object} data - Data source to render coupon details
   * @param {Number} id - Unique ID
   * @returns JSX.Element
   */

  const getTextComponent = (dataInfo, idVal) => (
    <div data-testid='coupon-container' key={idVal} className='coupon-card-container text-only'>
      <div className='coupon-card-content text-only'>
        <div className={getClassName('coupon-text-content coupon-text-only', 'show', isOpen)}>
          {getTimerAndHeader(dataInfo)}

          <h4 data-testid='coupon-heading-block' className='coupon-heading'>
            {sanitizeContent(dataInfo?.heading)}
          </h4>
          <h5 data-testid='coupon-desc-block' className='coupon-desc'>
            {sanitizeContent(dataInfo?.description)}
          </h5>
          <h5 data-testid='coupon-terms-btn' onClick={() => toggleTerms(!isOpen)} className='coupon-terms'>
            {translateWithI18Next(TERMS_AND_CONDITIONS)}
            <img src={ArrowIcon} alt='' className={getClassName('terms-arrow', 'inverted', isOpen)} />
          </h5>
        </div>
        <div className='coupon-button-container'>
          <ButtonComp
            data-testid='apply-button'
            disabled={dataInfo?.isApplied}
            onClick={e => applyCoupon(e, dataInfo?.discountCode)}
            className='button whiteButton blackBorder'
            aria-label={translateWithI18Next(REDEEM) + ' ' + dataInfo?.heading}
          >
            <span className='btn-text'>
              {dataInfo?.isApplied ? translateWithI18Next(OFFER_APPLIED) : translateWithI18Next(REDEEM)}
            </span>
          </ButtonComp>
        </div>
      </div>
      <div data-testid='terms-list-block' className={getClassName('terms-list', 'show', isOpen)}>
        {/* <h4 className='terms-headline'>{TERMS_HEADLINE}</h4> */}
        <ul className='main-terms-list'>
          {dataInfo?.termsAndConditions?.map(terms => (
            <li key={terms?.message}>{terms?.message}</li>
          ))}
        </ul>
      </div>
    </div>
  );

  const getTimerAndHeader = dataInfo => {
    return (
      <>
        {dataInfo?.showTimer ? (
          isTimeValid(dataInfo?.endTime) && (
            <p data-testid='coupon-header-block' className='coupon-time'>
              <span data-testid='coupon-time-block' className='coupon-time-block'>
                <img className='coupon-time-icon' src={TimerIcon} alt='' />
                <TimerComponent endTime={dataInfo?.endTime} />
              </span>
            </p>
          )
        ) : (
          <p data-testid='coupon-header-block' className='mr-btm-0'>
            {dataInfo?.header && <span className='ml-rgt coupon-time'>{dataInfo?.header}</span>}
            <span className='coupon-expiry-details'>
              <img alt='' src={Time_ClockDark} />
              <span>
                <span className='capitalize'>Expires&nbsp;</span> on {getValidFormatDatePerYear(dataInfo?.endTime)}
              </span>
            </span>
          </p>
        )}
      </>
    );
  };
  /**
   * Method to render cards with image
   * @param {Object} data - Data source to render coupon details
   * @param {Number} id - Unique Id
   * @returns JSX.Element
   */
  const getImageTextComponent = (dataInfo, idVal) => (
    <section data-testid='coupon-container' key={idVal} className='coupon-card-container'>
      <section className='coupon-card-content text-image'>
        <section className='coupon-image-content'>
          <ImageComponent
            className='coupon-image'
            srcFile={getFullImagePath(dataInfo?.imageSrc, tenantData, false, false, true)}
            alt=''
            defaultPath={`${config(TENANT_IMAGE_BASE_URL)}${tenantId}${DEFAULT_IMAGE_PATH}`}
          />
        </section>
        <section className='coupon-text-content image-text'>
          {getTimerAndHeader(data)}
          <h4 data-testid='coupon-heading-block' className='coupon-heading margin-bottom-5'>
            {sanitizeContent(dataInfo?.heading)}
          </h4>
          <h5 data-testid='coupon-desc-block' className='coupon-desc margin-bottom-5'>
            {sanitizeContent(dataInfo?.description)}
          </h5>
          <h5 data-testid='coupon-terms-btn' onClick={() => toggleTerms(!isOpen)} className='coupon-terms'>
            {translateWithI18Next(TERMS_AND_CONDITIONS)}
            <img src={ArrowIcon} alt='' className={getClassName('terms-arrow', 'inverted', isOpen)} />
          </h5>
        </section>
        <section className='coupon-button-container'>
          <ButtonComp
            data-testid='apply-button'
            disabled={dataInfo?.isApplied}
            onClick={e => applyCoupon(e, dataInfo?.discountCode)}
            className='button whiteButton blackBorder'
            aria-label={translateWithI18Next(REDEEM) + ' ' + dataInfo?.heading}
          >
            <span className='btn-text'>
              {data?.isApplied ? translateWithI18Next(OFFER_APPLIED) : translateWithI18Next(REDEEM)}
            </span>
          </ButtonComp>
        </section>
      </section>
      <section data-testid='terms-list-block' className={getClassName('terms-list', 'show', isOpen)}>
        {/* <h4>{TERMS_HEADLINE}</h4> */}
        <ul className='main-terms-list'>
          {dataInfo?.termsAndConditions?.map(terms => (
            <li key={terms?.message}>{terms?.message}</li>
          ))}
        </ul>
      </section>
    </section>
  );

  return (
    <React.Fragment>
      {cardType === 'image-text' ? getImageTextComponent(data, id) : getTextComponent(data, id)}
    </React.Fragment>
  );
};

CouponCardDesktop.propTypes = {
  data: PropTypes.object,
  cardType: PropTypes.string,
  tenantId: PropTypes.string,
  id: PropTypes.number,
  applyCoupon: PropTypes.func,
  tenantData: PropTypes.object,
};

CouponCardDesktop.defaultProps = {
  data: {},
  cardType: 'text',
  tenantId: '',
  id: uuidv4(),
  applyCoupon: emptyFunction,
  tenantData: {},
};

export default CouponCardDesktop;
