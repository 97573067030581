import { OFFERS_AND_PROMOTIONS_CONSTANTS } from '../Constants/OffersAndPromotionsConstants';
import { getDuration } from 'common/utilities/dayJsUtils';
import { jsontoObjects, getConditions, getMarkedHeading } from '../../CouponsLanding/utils/CouponUtils';
import { AnalyticsService } from '@kfc-global/react-shared/analytics';
import { getDataLayer } from '../../../common/utilities/utils';
import events from '@kfc-global/react-shared/analytics/Register';
import { CART_PAGE_CONSTANTS } from 'organisms/CartPage/Constants/CartPageConstants';
import { logEvent } from 'common/utilities/analyticsUtils';
const { ANALYTICS_EVENTS } = CART_PAGE_CONSTANTS;
/**
 *
 * @param {*} data - all offers data - Array
 */

const { URL, PNG } = OFFERS_AND_PROMOTIONS_CONSTANTS;

export const modifyData = (data = []) => {
  const {
    URL = '',
    BASIC_RATE = '',
    HEADER = '',
    SHOW_TIMER = '',
    TIME_IN_MILLISECONDS = null,
  } = OFFERS_AND_PROMOTIONS_CONSTANTS;

  return data.map(item => {
    const {
      cardType = '',
      couponCode = '',
      description = [],
      shortDescription = [],
      tags = [],
      image = [],
      end = '',
    } = item;
    const imageSrc = (image.length > 0 && URL + image[0].value) || '';
    const fullText = description.map(itemVal => itemVal.lines) || '';
    const smallText = (shortDescription.length > 0 && shortDescription[0].value) || '';
    const titleValue = (item.title.length > 0 && item.title[0].value) || '';
    let BasicRateValue = '',
      HeaderValue = '',
      ShowTimerValue = '',
      validityEndTime = null,
      currentTime = Date.now();
    validityEndTime = getDuration(Number(end) - Number(currentTime / TIME_IN_MILLISECONDS), 'seconds').format(
      'HH[HR] : mm[M] : ss[S]',
    );
    tags.forEach(element => {
      if (element.key === BASIC_RATE) {
        BasicRateValue = element.value;
      }
      if (element.key === HEADER) {
        HeaderValue = element.value;
      }
      if (element.key === SHOW_TIMER) {
        ShowTimerValue = element.value;
      }
    });

    return {
      cardType,
      couponCode,
      smallText,
      fullText,
      titleValue,
      imageSrc,
      BasicRateValue,
      HeaderValue,
      ShowTimerValue,
      validityEndTime,
      currentTime,
      end,
    };
  });
};

/**
 * Method to set analytics items in the data layer object
 */
export const setOffersAndPromotionsAnalyticsData = async analyticsData => {
  const analyticsItems = [];
  analyticsItems.push(analyticsData);
  await AnalyticsService.registerEvent(
    events?.viewAllDeals?.name,
    {
      data: analyticsItems[0],
    },
    getDataLayer,
  );
};
export const setRedeemOffersAndPromotionsAnalyticsData = analyticsData => {
  logEvent(events?.redeemOffer?.name, {
    data: analyticsData,
  });
};
export const modifyOffersData = data =>
  data.map(coupon => ({
    couponCode: coupon?.code,
    titleValue:
      coupon?.promotionDetails?.title &&
      coupon?.promotionDetails?.title.length &&
      getMarkedHeading(coupon?.promotionDetails?.title[0]?.value),
    smallText:
      coupon?.promotionDetails?.description &&
      coupon?.promotionDetails?.description.length &&
      coupon?.promotionDetails?.description[0]?.value,
    imageSrc:
      coupon?.promotionDetails?.image &&
      coupon?.promotionDetails?.image.length &&
      URL + coupon?.promotionDetails?.image[0]?.value + PNG,
    validityEndTime: coupon?.endDateTime,
    termsAndConditions: getConditions(coupon?.promotionDetails?.conditions),
    isApplicable: coupon?.isApplicable,
    isApplied: coupon?.isApplied,
    isRewards: coupon?.isRewards,
    cardType: coupon?.promotionDetails?.cardType,
    ...jsontoObjects(coupon?.promotionDetails?.customProperties),
  }));

export const handleRedeemClick = (discountCode, couponApplyHandler, handleClose) => {
  setRedeemOffersAndPromotionsAnalyticsData({
    event: ANALYTICS_EVENTS.REDEEM_OFFER,
    offerTitle: discountCode,
  });
  couponApplyHandler(discountCode);
  if (handleClose) {
    handleClose();
  }
};
